import React, { useEffect, useState,useRef } from "react";
import { PlusSmallIcon } from "@heroicons/react/24/outline";
import { Country, State, City } from "country-state-city";
import getCityState from "../../BaseFile/getCityState";
import { Dialog } from "@headlessui/react";
import Spinner from "../../BaseFile/Spinner";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import OtpVerification from "./OtpVerification";
import {Ads} from "../../AdsComponents/Ads"
import {
  sendOtp,
  signUpUser,
  clearAuthErrors,
  clearAuthMessage,
} from "../../redux/authSlice";
import SuccessAlert from "../../BaseFile/SuccessAlert";
import ErrorAlert from "../../BaseFile/ErrorAlert";
import SwiperSlider from "../../AdsComponents/SwiperSlider";
import Footer from "../../HomeFiels/Comman/Footer";
import Header from "../../HomeFiels/Comman/Header";

const UserRegistration = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const resetButtonRef = useRef(null);
  const [states, setStates] = useState([]);
  const [open, setOpen] = useState(false);
  const [data, setData] = useState();
  const [activeStateCode, setActiveStateCode] = useState(null);
  const [selectedState, setSelectedState] = useState(null);
  const [cities, setCities] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedCities, setSelectedCities] = useState({});
  const [selectedStates, setSelectedStates] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [OTPverification, setOTPverification] = useState(false);

  const [values, setValues] = useState({
    username: "",
    email: "",
    password: "",
    confirmPassword: "",
    phone: "",
    address: "",
    pincode: "",
    starting_date: "",
  });
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [localError, setLocalError] = useState("");
  const { auth, auth_loading, auth_error, auth_message } = useSelector(
    (state) => state.auth
  );

  useEffect(() => {
    if (values.pincode) {
      getCityState(values.pincode)
        .then((location) => {
          if (location) {
            setState(location.state);
            setCity(location.city);
            setLocalError(null);
          } else {
            setLocalError("Could not fetch location details.");
          }
        })
        .catch((error) => console.error("Error:", error.message));
    }

    if (auth_error) {
      const errorInterval = setInterval(() => {
        dispatch(clearAuthErrors());
      }, 3000);
      return () => clearInterval(errorInterval);
    }
    if (auth_message && auth_message=='OTP verify successfully.'){
      setOTPverification(true);
     }
    if (auth_message) {
      const messageInterval = setInterval(() => {
        dispatch(clearAuthMessage());
      }, 3000);
      return () => clearInterval(messageInterval);
    }
    if (auth) {
      navigate(`/`);
    }
  }, [values.pincode, auth_message, auth_error]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    setValues((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  function resetForm() {
    setValues({
      username: "",
      email: "",
      password: "",
      confirmPassword: "",
      phone: "",
      address: "",
      pincode: "",
      investment_range: "",
      preferred_cities: "",
      intrested_segment: "",
      categories: "",
      starting_date: "",
    });
    setSelectedCities({});
    setSelectedStates({});
    setCity("");
    setState("");
  }

  useEffect(() => {
    const indianStates = State.getStatesOfCountry("IN");
    setStates(indianStates);
  }, []);


  // Handle city checkbox change inside modal
  const handleStateClick = (stateIsoCode) => {
    const isStateSelected = !!selectedStates[stateIsoCode];
    setSelectedStates((prev) => {
      const updatedStates = { ...prev };
      if (isStateSelected) {
        delete updatedStates[stateIsoCode];
      } else {
        updatedStates[stateIsoCode] = true;
        setSelectedCities((prevCities) => {
          const updatedCities = { ...prevCities };
          delete updatedCities[stateIsoCode];
          return updatedCities;
        });
      }
      return updatedStates;
    });
  };

  const handleCityClick = (stateIsoCode, cityName) => {
    setSelectedCities((prev) => {
      const updatedCities = { ...prev };
      const stateCities = updatedCities[stateIsoCode] || [];
      if (stateCities.includes(cityName)) {
        updatedCities[stateIsoCode] = stateCities.filter(
          (city) => city !== cityName
        );
      } else {
        updatedCities[stateIsoCode] = [...stateCities, cityName];
      }
      return updatedCities;
    });
  };

  const handleStateDisclosureClick = (stateIsoCode) => {
    if (!cities[stateIsoCode]) {
      const cityData = City.getCitiesOfState("IN", stateIsoCode);
      setCities((prevCities) => ({ ...prevCities, [stateIsoCode]: cityData }));
    }
    setActiveStateCode(stateIsoCode);
    setIsModalOpen(true);
  };

  const handleSegmentChange = (e) => {
    const { options } = e.target;
    const selectedValues = [];

    for (let i = 0; i < options.length; i++) {
      if (options[i].selected) {
        selectedValues.push(options[i].value);
      }
    }

    if (selectedValues.length <= 5) {
      setValues({ ...values, interested_segment: selectedValues });
    } else {
      alert("You can only select up to 5 segments.");
    }
  };

const handleVerify=(e)=>{
  const email = values.email;
  if (email.includes("yopmail")) {
    window.alert("Not a valid Email , yopmail is not allowed");
    setValues({ email: "" });
    return;
  } else {
    setOpen(true);
    dispatch(sendOtp(email));
  }
}

  const handleSubmit = (e) => {
    e.preventDefault();
    const userData = {
      selectedCities: selectedCities,
      selectedStates: selectedStates,
      ...values,
    };
    if(OTPverification){
      dispatch(signUpUser(userData));
      resetForm();
      setOTPverification(false);
      resetButtonRef.current.click();
    }
  };
  return (
    <>
      {auth_message && <SuccessAlert message={auth_message} />}
      {auth_error && <ErrorAlert error={auth_error} />}
      <Header />
      <div className="max-w-full lg:mx-10 mx-4">
        <div className=" overflow-x-auto mt-4">
          <div className="w-full  lg:pl-0 mb-4 my-3">
            <div className="flex justify-between text-sm  ">
              <div className="text-gray-600 flex items-center pb-2 lg:pr-2  border-b-2 border-gray-600 capitalize">
                <h2 className="font-semibold  lg:text-2xl sm:text-xl text-lg text-gray-800 inline-block">
                  Register Page
                </h2>
              </div>
            </div>
          </div>
          <div className="">
            <p className="md:text-base mb-4 text-justify lg:pr-6">
              We’re excited to have you as part of our community, where we
              empower both aspiring and seasoned franchise investors.
              Registering for an account will give you access to exclusive
              features and resources designed to enhance your franchise journey.
            </p>
          </div>
        </div>
        <div className="grid grid-cols-12 gap-4">
          <div className="lg:col-span-3 col-span-12 mt-6">
            <div className="grid grid-cols-1 shadow-sm bg-white border">
              {/* <SwiperSlider /> */}
              {/* <div className="relative">
                <div className="text-base absolute top-0 capitalize right-0 bg-black px-4 py-2 text-white mt-3 mr-3 font-medium hover:bg-black  transition duration-500 ease-in-out">
                  ads
                </div>
                <img src="/registerad.png" className="w-full" />
              </div> */}
              <Ads adnum={1}/> 

              <div className="relative mt-1">
                <div className="text-base absolute -top-3 capitalize -right-3 bg-black px-4 py-2 text-white mt-3 mr-3 font-medium hover:bg-black  transition duration-500 ease-in-out">
                  ads
                </div>
                {/* <img src="/registerad1.png" className="w-full" /> */}
              <Ads adnum={2}/> 

              </div>
              <div className="relative mt-1">
                <div className="text-base absolute -top-3 capitalize -right-3 bg-black px-4 py-2 text-white mt-3 mr-3 font-medium hover:bg-black  transition duration-500 ease-in-out">
                  ads
                </div>
                {/* <img src="/registerads2.png" className="" /> */}
              <Ads adnum={2}/> 
              </div>
            </div>
          </div>
          <div className="lg:col-span-6 col-span-12">
            <form onSubmit={handleSubmit}>
              <div className="relative mt-1 mb-6 px-2"></div>
              <div className="bg-white  shadow-sm border rounded-sm pb-8">
                <div className="bg-gray-100">
                  <h2 className="text-base font-semibold mb-4 border-b py-3 px-6">
                    User Registration ({" "}
                    <span className="text-red-500">
                      {" "}
                      * fields are required{" "}
                    </span>
                    )
                  </h2>
                </div>
                {/* <form onSubmit={handleSubmit}> */}
                <div className="px-4 lg:px-6 py-2">
                  <div className="grid lg:grid-cols-2 grid-cols-1 gap-4">
                    <div>
                      <label
                        htmlFor="username"
                        className="block font-medium text-[13px] text-gray-900"
                      >
                        Full Name <span className="text-red-500"> * </span>
                      </label>
                      <input
                        type="text"
                        id="username"
                        name="username"
                        value={values.username}
                        onChange={(e) => handleChange(e)}
                        required
                        placeholder="Enter Your Username"
                        className="w-full rounded-sm border text-[12px] py-2 px-3 border-gray-400"
                      />
                    </div>
                    <div className="relative">
                      <label
                        htmlFor="email"
                        className="block font-medium text-[13px] text-gray-900"
                      >
                        Email <span className="text-red-500"> * </span>
                      </label>
                      <input
                        type="email"
                        id="email"
                        value={values.email}
                        name="email"
                        onChange={(e) => handleChange(e)}
                        required
                        placeholder="Enter Your Email"
                        className="w-full rounded-sm border text-[12px] py-2 px-3 border-gray-400 "
                      />
                      <button 
                      onClick={handleVerify}
                      disabled={!values?.email}
                      className={`px-4 py-[9px] bg-indigo-500 text-white font-medium text-[12px] absolute right-0 rounded-r-sm ${
                        !values?.email ? "opacity-50 cursor-not-allowed" : ""
                      }`}>Verify</button>
                    </div>
                   
                  </div>
                  <div className="grid lg:grid-cols-2 grid-cols-1 gap-4 mt-4">
                    <div>
                      <label
                        htmlFor="password"
                        className="block font-medium text-[13px] text-gray-900"
                      >
                        Password <span className="text-red-500"> * </span>
                      </label>
                      <input
                        type="password"
                        name="password"
                        value={values.password}
                        onChange={(e) => handleChange(e)}
                        required
                        id="password"
                        placeholder="Enter Your Password"
                        className="w-full rounded-sm border text-[12px] py-2 px-3 border-gray-400"
                      />
                    </div>
                    <div>
                      <label
                        htmlFor="confirmPassword"
                        className="block font-medium text-[13px] text-gray-900"
                      >
                        Confirm Password{" "}
                        <span className="text-red-500"> * </span>
                      </label>
                      <input
                        type="password"
                        value={values.confirmPassword}
                        name="confirmPassword"
                        onChange={(e) => handleChange(e)}
                        required
                        id="confirmPassword"
                        placeholder="Enter Your Password"
                        className="w-full rounded-sm border text-[12px] py-2 px-3 border-gray-400"
                      />
                    </div>
                  </div>
                  <div className="grid lg:grid-cols-2 grid-cols-1 gap-4 mt-4">
                    <div>
                      <label
                        htmlFor="phone"
                        className="block font-medium text-[13px] text-gray-900"
                      >
                        Mobile <span className="text-red-500"> * </span>
                      </label>
                      <input
                        onChange={(e) => handleChange(e)}
                        name="phone"
                        required
                        value={values.phone}
                        type="text"
                        id="phone"
                        placeholder="Enter Your Phone Number"
                        className="w-full rounded-sm border text-[12px] py-2 px-3 border-gray-400"
                      />
                    </div>
                    <div>
                      <label
                        htmlFor="pincode"
                        className="block font-medium text-[13px] text-gray-900"
                      >
                        Pin Code <span className="text-red-500"> * </span>
                      </label>
                      <input
                        type="text"
                        onChange={(e) => handleChange(e)}
                        id="pincode"
                        value={values.pincode}
                        name="pincode"
                        required
                        placeholder="Enter Your Pin Code"
                        className="w-full rounded-sm border text-[12px] py-2 px-3 border-gray-400"
                      />
                    </div>
                  </div>

                  <div className="mt-3">
                    <label
                      htmlFor="address"
                      className="block leading-6 text-[13px] text-gray-900"
                    >
                      Address <span className="text-red-500"> * </span>
                    </label>
                    <div className="mt-1">
                      <textarea
                        id="address"
                        onChange={(e) => handleChange(e)}
                        value={values.address}
                        name="address"
                        required
                        rows={4}
                        placeholder="Please Enter Your Address"
                        className="w-full rounded-sm border text-[12px] py-2 px-3 border-gray-400"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg-white  shadow-sm border rounded-sm pb-4 mt-5">
                <div className="bg-gray-100">
                  <h2 className="text-base font-semibold mb-4 border-b py-3 px-6">
                    Interests & Resources
                  </h2>
                </div>
                <div className="grid lg:grid-cols-2 grid-cols-1 gap-4 px-6 ">
                  <div>
                    <div className="">
                      <label
                        htmlFor="Investment"
                        className="block font-medium text-[13px] text-gray-900"
                      >
                        Investment Range{" "}
                        <span className="text-red-500"> * </span>
                      </label>
                      <div className="flex rounded-sm shadow-sm ring-1 text-[12px] ring-inset ring-gray-300 focus-within:ring-inset focus-within:ring-indigo-600 ">
                        <select
                          name="investment"
                          type="text"
                          required
                          value={values.investment}
                          onChange={(e) => handleChange(e)}
                          className="block flex-1 border-0 bg-transparent text-[12px] py-2.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:leading-6"
                        >
                          <option value="Rs. 10000 - 50000">
                            Rs. 10000 - 50000
                          </option>
                          <option value="Rs. 50000 - 2lakh">
                            Rs. 50000 - 2lakh
                          </option>
                          <option value="Rs. 2lakh - 5lakh">
                            Rs. 2lakh - 5lakh
                          </option>
                          <option value="Rs. 5lakh - 10lakh">
                            Rs. 5lakh - 10lakh
                          </option>
                          <option value="Rs. 10lakh - 20lakh">
                            Rs. 10lakh - 20lakh
                          </option>
                          <option value="Rs. 20lakh - 30lakh">
                            Rs. 20lakh - 30lakh
                          </option>
                          <option value="Rs. 30lakh - 50lakh">
                            Rs. 30lakh - 50lakh
                          </option>
                          <option value="Rs. 50lakh - 1 Cr">
                            Rs. 50lakh - 1 Cr.
                          </option>
                          <option value=" Rs. 1 Cr. - 2 Cr">
                            {" "}
                            Rs. 1 Cr. - 2 Cr.
                          </option>
                          <option value="Rs. 2 Cr. - 5 Cr">
                            Rs. 2 Cr. - 5 Cr.
                          </option>
                          <option value="Rs. 5 Cr. above">
                            Rs. 5 Cr. above
                          </option>
                        </select>
                      </div>
                    </div>
                    <div>
                      <label
                        htmlFor="starting_date"
                        className="block font-medium text-[13px] text-gray-900"
                      >
                        When Would You Like To Start Your Franchise?{" "}
                        <span className="text-red-500"> * </span>
                      </label>
                      <input
                        type="date"
                        onChange={(e) => handleChange(e)}
                        id="starting_date"
                        value={values.starting_date}
                        name="starting_date"
                        required
                        placeholder="Enter Your Pin Code"
                        className="w-full rounded-sm border text-[12px] py-2 px-3 border-gray-400"
                      />
                    </div>
                  </div>

                  <div className="">
                    <label
                      htmlFor="interested_segment"
                      className="block font-medium text-[13px] text-gray-900"
                    >
                      Interested Segment (Select Using CTRL .max. 5){" "}
                      <span className="text-red-500"> * </span>
                    </label>
                    <div className="flex rounded-sm shadow-sm ring-1 text-[12px] ring-inset ring-gray-300 focus-within:ring-inset focus-within:ring-indigo-600">
                      <select
                        name="interested_segment"
                        multiple
                        required
                        value={values.interested_segment}
                        onChange={(e) => handleSegmentChange(e)}
                        className="block flex-1 border-0 bg-transparent text-[12px] capitalize py-2.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:leading-6"
                      >
                        <option value="Automotive">Automotive</option>
                        <option value="Business Services">
                          Business Services
                        </option>
                        <option value="Dealers & Distributors">
                          Dealers & Distributors
                        </option>
                        <option value="Education">Education</option>
                        <option value="Fashion">Fashion</option>
                        <option value="Home Based Business">
                          Home Based Business
                        </option>
                        <option value="Hotel, Travel & Tourism">
                          Hotel, Travel & Tourism
                        </option>
                        <option value="Retail">Retail</option>
                        <option value="Sports, Fitness & Entertainment">
                          Sports, Fitness & Entertainment
                        </option>
                        <option value="Beauty & Health">Beauty & Health</option>
                      </select>
                    </div>
                  </div>
                </div>

                <div className="bg-white  shadow-sm border rounded-sm mt-6">
                  <div className="bg-gray-100">
                    <h2 className="text-base font-semibold leading-7 border-b py-3 px-6 text-gray-900">
                      Intrested State
                    </h2>
                  </div>

                  <div className="w-full py-2">
                    <div className="mt-4 grid lg:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-x-12 gap-y-3 px-6 pb-4">
                      {states.map((state) => (
                        <div
                          key={state.isoCode}
                          className="flex justify-between items-start"
                        >
                          <div className="flex items-center mb-2">
                            <input
                              id={`${state.isoCode}State`}
                              name="state"
                              type="checkbox"
                              checked={!!selectedStates[state.isoCode]}
                              className="h-4 w-4 border-gray-300 text-green-600 focus:ring-green-600"
                              onChange={() => handleStateClick(state.isoCode)}
                            />
                            <label
                              htmlFor={`${state.isoCode}State`}
                              className="ml-3   text-[12px] font-semibold whitespace-pre-line break-all text-gray-900"
                            >
                              {state.name ===
                              "Dadra and Nagar Haveli and Daman and Diu"
                                ? "Dadar & Daman"
                                : state.name === "Andaman and Nicobar Islands"
                                ? "Andaman"
                                : state.name}
                            </label>
                          </div>

                          {!selectedStates[state.isoCode] && (
                            <button
                              type="button"
                              onClick={() =>
                                handleStateDisclosureClick(state.isoCode)
                              }
                              className="flex justify-start gap-2  items-center  text-left text-gray-900 font-semibold text-lg"
                            >
                              <div className="flex items-center gap-2 bg-gray-800 px-1 py-[3px] rounded-sm">
                                <span className="  text-white">
                                  <PlusSmallIcon className="h-5 w-5 text-white" />
                                </span>
                                {/* <span className="text-[12px] text-white"> Cities</span> */}
                              </div>
                            </button>
                          )}
                        </div>
                      ))}
                    </div>
                    <Dialog
                      open={isModalOpen}
                      onClose={() => setIsModalOpen(false)}
                      className="fixed inset-0 z-10 flex items-center justify-center p-4"
                    >
                      <div
                        className="fixed inset-0 bg-black bg-opacity-25"
                        aria-hidden="true"
                      />
                      <Dialog.Panel className="relative bg-white rounded-lg max-w-2xl mx-auto p-6 h-[500px] overflow-y-scroll">
                        <Dialog.Title className="text-lg  font-semibold text-gray-900 border-b pb-4 border-gray-400">
                          Cities in{" "}
                          {activeStateCode &&
                            states.find((s) => s.isoCode === activeStateCode)
                              ?.name}
                        </Dialog.Title>
                        <Dialog.Description className="mt-2">
                          {cities[activeStateCode] ? (
                            cities[activeStateCode].length > 0 ? (
                              <div className="mt-4 grid grid-cols-4 gap-4">
                                {cities[activeStateCode].map((city) => (
                                  <div
                                    key={city.name}
                                    className="flex items-center"
                                  >
                                    <input
                                      type="checkbox"
                                      className="mr-2"
                                      checked={
                                        selectedCities[
                                          activeStateCode
                                        ]?.includes(city.name) || false
                                      }
                                      onChange={() =>
                                        handleCityClick(
                                          activeStateCode,
                                          city.name
                                        )
                                      }
                                    />
                                    <label className="text-gray-700 text-[12px] whitespace-pre-line break-all">
                                      {city.name}
                                    </label>
                                  </div>
                                ))}
                              </div>
                            ) : (
                              <p>No cities available</p>
                            )
                          ) : (
                            <p>Loading cities...</p>
                          )}
                        </Dialog.Description>
                        <div className="mt-4 justify-end flex border-t border-gray-400 pt-2">
                          <button
                            onClick={() => setIsModalOpen(false)}
                            className="mt-3 px-10  inline-flex justify-center rounded-md bg-indigo-600 text-[12px] py-2 text-white font-semibold shadow-sm hover:bg-indigo-500"
                          >
                            Close
                          </button>
                        </div>
                      </Dialog.Panel>
                    </Dialog>
                  </div>
                </div>

                <div className="flex flex-wrap px-6 py-4 border-t mt-4">
                  {/* Left Column */}
                  <div className="w-full ">
                    <h2 className="font-semibold text-sm mb-4">
                      Tick Preferred Categories to suit your requirements?
                    </h2>
                    <div className="space-y-2 text-[13px]">
                      <label className="block">
                        <input
                          type="checkbox"
                          className="mr-2"
                          name="franchisee"
                          checked={values.franchisee}
                          onChange={handleChange}
                        />{" "}
                        Franchise Opportunities
                      </label>
                      <label className="block">
                        <input
                          type="checkbox"
                          className="mr-2"
                          name="dealer"
                          checked={values.dealer}
                          onChange={handleChange}
                        />{" "}
                        Becoming A Dealer
                      </label>
                      <label className="block">
                        <input
                          type="checkbox"
                          className="mr-2"
                          name="distributor"
                          checked={values.distributor}
                          onChange={handleChange}
                        />{" "}
                        Becoming A Distributor
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className=" flex items-center justify-end mt-6 gap-5">
                <button
                   type="reset"
                   ref={resetButtonRef}
                  className=" bg-red-600  text-white font-bold py-1.5 px-6 rounded-sm hover:from-purple-500 hover:to-pink-500 transition-transform transform hover:-translate-y-1 duration-300"
                >
                  Reset
                </button>
                <button
                  type="submit"
                  disabled={!OTPverification}
                  className={`px-4 py-[9px] bg-indigo-500 text-white font-medium text-[12px]  right-0 rounded-r-sm ${
                    !OTPverification ? "opacity-50 cursor-not-allowed" : ""
                  }`}>
                  {auth_loading ? <Spinner /> : "Register"}
                </button>
              </div>
            </form>
          </div>

          <div className="lg:col-span-3 mt-6 col-span-12">
            <div className="bg-white  shadow-sm border rounded-sm pb-8 mb-10">
              <div className="bg-gray-100 ">
                <h2 className="text-base font-semibold mb-4 border-b py-3 px-6">
                  Why Register?
                </h2>
              </div>
              {/* <form onSubmit={handleSubmit}> */}
              <div className="px-4 lg:px-6 py-2">
                <div className=" text-justify ">
                  <span className="font-medium float-left">
                    Access Franchise Listings :-{" "}
                  </span>
                  <p className="">
                    Discover a wide variety of franchise opportunities tailored
                    to your interests and investment goals.
                  </p>
                </div>
                <div className=" text-justify mt-3 ">
                  <span className="font-medium float-left">
                    Stay Informed :-{" "}
                  </span>
                  <p className="">
                    Receive newsletters packed with the latest industry trends,
                    tips, and insights to guide your franchising decisions.
                  </p>
                </div>
                <div className=" text-justify mt-3">
                  <span className="font-medium float-left">
                    Investor Plans :-{" "}
                  </span>
                  <p className="">
                    Explore our tiered investment strategies that provide
                    personalized support and tools for informed decision-making.
                  </p>
                </div>
              </div>
            </div>
            {/* <div className="grid grid-cols-1 shadow-sm bg-white border">
              <SwiperSlider />
            </div> */}
            <div className="relative mt-4">
              <div className="text-sm absolute -top-3 capitalize left-0 bg-black px-4 py-1 text-white mt-3 mr-3 font-medium hover:bg-black  transition duration-500 ease-in-out">
                ads
              </div>
              {/* <img src="/devOps.png" className="" /> */}
              <Ads adnum={3}/> 

            </div>
            <div className="relative mt-8">
              <div className="text-sm absolute -top-3 capitalize left-0 bg-black px-4 py-1 text-white mt-3 mr-3 font-medium hover:bg-black  transition duration-500 ease-in-out">
                ads
              </div>
              {/* <img src="/banner.jpg" className="" /> */}
              <Ads adnum={3}/> 
            </div>
          </div>
        </div>
        <Footer />
      </div>
      {open && (
        <OtpVerification
          open={open}
          email={values?.email}
          setOpen={() => setOpen(false)}
        />
      )}
    </>
  );
};

export default UserRegistration;
