import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Helmet } from "react-helmet"; 
import AdminPrivateRoute from "./Admin/AdminBaseFile/AdminPrivateRoutes";
import UserPrivateRoute from "./User/UserBaseFile/UserPrivateRoutes";
import Home from "./HomeFiels/Home/Home"
import Login from "./BaseFile/Login"
import UserRegistration from "./User/UserBaseFile/UserRegistration"
import InvestorRegistration from "./Investor/InvestorBaseFile/InvestorRegistration";
import InvestorPrivateRoute from "./Investor/InvestorBaseFile/InvestorPrivateRoutes";
import InvestorMenu from "./Investor/InvestorMenu";
import UserMenu from "./User/UserMenu"
import AdminMenu from "./Admin/AdminMenu"
import AdminDashboard from "./Admin/AdminDashboard";
import UserDashboard from "./User/UserDashboard";
import AdminLogin from "./Admin/AdminBaseFile/AdminLogin"
import BrandFriendly from "./HomeFiels/Pages/BrandFriendly"
import AdminUsers from "./Admin/AdminUsers"
import AdminFranchise from "./Admin/AdminFranchise";
import AdminKyc from "./Admin/AdminKyc"
import AdminFranchiseeProfile from "./Admin/AdminFranchiseeProfile";
import AdminUserProfile from "./Admin/AdminUserProfile";
import AdminBuyFranchise from "./Admin/AdminBuyFranchise";
import UserOwnedFranchiseProfile from "./User/UserOwnedFranchiseProfile";
import UserProfile from "./User/UserProfile";
import InvestorProfile from "./Investor/InvestorProfile";
import AddUserPlan from "./Admin/AddUserPlan";
import AddInvestorPlan from "./Admin/AddInvestorPlan";
import ViewAllFranchise from "./HomeFiels/Pages/VIewAllFranchise";
import Terms from "./HomeFiels/StaticPage/Terms";
import PrivacyPolicy from "./HomeFiels/StaticPage/PrivacyPolicy";
import SupportCenter from "./HomeFiels/StaticPage/SupportCenter";
import FranchiseDisclosure from "./HomeFiels/StaticPage/FranchiseDisclosure";
import Career from "./HomeFiels/StaticPage/Career";
import News from "./HomeFiels/StaticPage/News";
import AboutFranchise from "./HomeFiels/StaticPage/About";
import ContactUs from "./HomeFiels/StaticPage/ContactUs";
import CookiesPolicy from "./HomeFiels/StaticPage/CookiesPolicy";
import FilteredFranchise from "./HomeFiels/Pages/FilteredFranchise";
import Marketing from "./HomeFiels/StaticPage/Marketing";
import Insights from "./HomeFiels/StaticPage/Insights";
import Commerce from "./HomeFiels/StaticPage/Commerce";
import { Analytics } from "./HomeFiels/StaticPage/Analytics"; 
import FranchiseDevelopment from "./HomeFiels/Home/FranchiseDevelopment";
import { Advertise } from "./HomeFiels/Home/Advertise";
import InvestorClientProfile from "./Investor/InvestorClientProfile";
import SearchResult from "./HomeFiels/Home/SearchResult";
import AdminMarketing from "./Admin/AdminMarketing";
import PopularSearch from "./HomeFiels/Pages/PopularSearch";
import AdminPaymentHistory from "./Admin/AdminPaymentHistory"
import ManageHome from "./Admin/ManageHome";
import ManageAds from "./Admin/ManageAds";
import LocationRegistration from "./LocationFiles/LocationRegistration";
 
 
function App() {
  
  return (
    <Router>
      <Helmet>
        <script
          type="text/javascript"
          src="//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"
        ></script>
        <script>
          {`function googleTranslateElementInit() {
            new google.translate.TranslateElement({pageLanguage: 'en'}, 'google_translate_element');
          }`}
        </script>
      </Helmet>

     


      <Routes>
      <Route path="/" element={ <Home/>}/>
      <Route path="/login" element={ <Login/>}/>
      <Route path="/way4franchise/mylogin" element={ <AdminLogin/>}/>
      <Route path="/user/registration" element={ <UserRegistration/>}/>
      <Route path="/investor/registration" element={ <InvestorRegistration/>}/>
      <Route path="/franchisedetail/:name/:_id" element={ <BrandFriendly/>}/>
      <Route path="/viewall" element={ <ViewAllFranchise/>}/>
      <Route path="/popularsearch" element={ <PopularSearch/>}/>
      <Route path="//search-result" element={ <SearchResult/>}/>
      <Route path="/franchise_dir" element={ <FilteredFranchise/>}/>
      <Route path="/term" element={ <Terms/>}/>
      <Route path="privacypolicy" element={ <PrivacyPolicy/>}/>
      <Route path="aboutfranchise" element={<AboutFranchise/>}/>
      <Route path="supportcenter" element={ <SupportCenter/>}/>
      <Route path="franchisedisclosure" element={ <FranchiseDisclosure/>}/>
      <Route path="career" element={ <Career/>}/>
      <Route path="news" element={ <News/>}/>
      <Route path="contactus" element={ <ContactUs/>}/>
      <Route path="cookiespolicy" element={ <CookiesPolicy/>}/>
      <Route path="franchisedevelopment" element={ <FranchiseDevelopment/>}/>
      <Route path="advertise" element={ <Advertise/>}/>
      <Route path="marketing" element={ <Marketing/>}/>
      <Route path="insights" element={ <Insights/>}/>
      <Route path="commerce" element={ <Commerce/>}/>
      <Route path="analytics" element={ <Analytics/>}/> 
      {/* <Route path="locationregistration" element={ <LocationRegistration/>}/>  */}
      


        <Route path="/" element={<AdminPrivateRoute/>}>
          <Route path="/admin/dashboard" element={ <AdminMenu Children={<AdminDashboard/>} PageName={"Dashboard"}/>}/>
           <Route path="/admin/users" element={ <AdminMenu Children={<AdminUsers/>} PageName={"Users list"}/>}/>
           <Route path="/admin/investors" element={ <AdminMenu Children={<AdminFranchise/>} PageName={"Franchise list"}/>}/>
           <Route path="/admin/kycRequest" element={ <AdminMenu Children={<AdminKyc/>} PageName={"KYC"}/>}/>
           <Route path="/admin/franchsieprofile/:_id" element={ <AdminMenu Children={<AdminFranchiseeProfile/>} PageName={"franchise profile"}/>}/>
           <Route path="/admin/userprofile/:id" element={ <AdminMenu Children={<AdminUserProfile/>} PageName={"User profile"}/>}/>
           <Route path="/admin/buydetail" element={ <AdminMenu Children={<AdminBuyFranchise/>} PageName={"Buy franchise Detail"}/>}/>
           <Route path="/admin/add/userplan" element={ <AdminMenu Children={<AddUserPlan/>} PageName={"Add User Plan"}/>}/>
           <Route path="/admin/add/investorplan" element={ <AdminMenu Children={<AddInvestorPlan/>} PageName={"Add Investor Plan"}/>}/>
           <Route path="/admin/marketing" element={ <AdminMenu Children={ <AdminMarketing/>}PageName={"Marketing Franchise"}/>}/>
           <Route path="/admin/payment/history" element={ <AdminMenu Children={ <AdminPaymentHistory/>}PageName={"Payment History"}/>}/>
           <Route path="/admin/manage/home" element={ <AdminMenu Children={ <ManageHome/>}PageName={"Manage Home"}/>}/>
           <Route path="/admin/manage/ads" element={ <AdminMenu Children={ <ManageAds/>}PageName={"Manage Ads"}/>}/>
        </Route>

        <Route path="/" element={<UserPrivateRoute/>}>
          <Route path="/user/dashboard" element={ <UserMenu Children={<UserDashboard/>} PageName={"User Dashboard"}/>}/>
          <Route path="/user/profile" element={ <UserMenu Children={<UserProfile/>} PageName={"User Profiles"}/>}/> 
          <Route path="/user/franchise/profile/:_id" element={ <UserMenu Children={<UserOwnedFranchiseProfile/>} PageName={"User Own franchise profile"}/>}/>
        </Route>

        
        <Route path="/" element={<InvestorPrivateRoute/>}>
        <Route path="/investor/profile" element={ <InvestorMenu Children={<InvestorProfile/>} PageName={"Franchise Profile"}/>}/>
        <Route path="/investor/client/profile/:id" element={ <InvestorMenu Children={<InvestorClientProfile/>} PageName={"client Profile"}/>}/>
        </Route>
      </Routes>
    </Router>
  );
}

export default App;
