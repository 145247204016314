import { Swiper as SwiperContainer, SwiperSlide } from "swiper/react";
import "swiper/css";
import React, { useState, useEffect } from "react";
import { getAllFranchise } from "../../redux/franchiseSlice";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import "swiper/css/free-mode";
import "swiper/css/autoplay";
import { FreeMode, Autoplay } from "swiper/modules";




const PremiumOpportunities = () => {
  const [isHovered, setIsHovered] = useState(false);
  const [hoveredIndex, setHoveredIndex] = useState();
  const dispatch = useDispatch();
  const { allfranchise, loading, error, message } = useSelector(
    (state) => state.franchise
  );

  useEffect(() => {
    dispatch(getAllFranchise());
  }, []);
  function HandleHover(bool, ind) {
    setIsHovered(bool);
    setHoveredIndex(ind);
  }
  const swiperRef = React.useRef(null);

  return (
    <section className="flex flex-col justify-center lg:pt-4 lg:px-3 max-w-full lg:mx-10 mx-6 lg:mt-2">
      <div className="w-full lg:pl-2 mb-4 px-2">
        <div className=" flex justify-between text-sm">
          <div className="text-gray-600 flex items-center pb-2 pr-2 border-b-2 border-gray-600 capitalize">
            <h2 className="font-semibold lg:text-2xl sm:text-xl text-lg text-gray-800 inline-block">
              Top Distributor item Available
            </h2>
          </div>
        </div>
      </div>
      <div className="w-full flex relative">
        <SwiperContainer
          ref={swiperRef}
          slidesPerView={2}
          freeMode={true}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          breakpoints={{
            100: {
              slidesPerView: 1,
              spaceBetween: 20,
            },
            640: {
              slidesPerView: 1,
              spaceBetween: 20,
            },
            768: {
              slidesPerView: 2,
              spaceBetween: 40,
            },
            1024: {
              slidesPerView: 4,
              spaceBetween: 40,
            },
          }}
          modules={[FreeMode, Autoplay]}
          className="mySwiper m-auto border-black"
        >
          {allfranchise?.slice(15,25)?.map((item, index) => (
            <SwiperSlide key={index} className="swiper-slider">
              <div className="relative bg-white shadow-lg rounded-lg py-4 border overflow-hidden">
                <div className="px-4 border-b">
                  {item?.isVerified && (
                    <div className="absolute top-0 right-0">
                      <div className="w-32 h-8 absolute top-4 -right-8">
                        <div className="h-full w-full bg-green-500 text-white text-center text-sm leading-8 font-semibold transform rotate-45">
                          Verified
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="pr-12">
                  <h3 className="font-semibold text-sm text-gray-700 mb-1">
                    {item.franchise_name}
                  </h3>
                  </div>
                  <div className="flex gap-3 items-center mb-4">
                  <div className="flex items-center text-yellow-500 ">
                    ⭐ <span className="ml-2">5 / 5</span>
                  </div>
                  <div className="text-gray-500 text-[12px]  ">
                    {item?.address}
                  </div>
                  </div>
                </div>
                <div className="px-4 mt-2">
                  <div className=" text-[12px] text-gray-700 mb-2 flex">
                    <h3 className="font-semibold pe-2 capitalize  text-[12px]">
                      Industry :
                    </h3>{" "}
                    {item?.industry}
                  </div>
                  <div className=" text-[12px] text-gray-700 mb-2 flex">
                    <h3 className="font-semibold pe-2 capitalize">Sector :</h3>{" "}
                    {item?.sector}
                  </div>

                  {/* Investment and Space */}
                  <div className=" text-[12px] text-gray-700 mb-2 flex justify-between items-start">
                    <h3 className="font-semibold pe-2 capitalize">
                      Investment Tier 1 :
                    </h3>{" "}
                    {item?.investment_range_tier_1}{" "}
                    <span className="text-gray-900 rounded-sm bg-green-100 shadow-xl border font-semibold px-2 text-[11px]">
                      INR
                    </span>
                  </div>
                  <div className=" text-[12px] text-gray-700 mb-2 flex justify-between items-start">
                    <h3 className="font-semibold pe-2 capitalize">
                      Investment Tier 2:
                    </h3>{" "}
                    {item?.investment_range_tier_2}{" "}
                    <span className="text-gray-900 rounded-sm bg-green-100 shadow-xl border font-semibold px-2 text-[11px]">
                      INR
                    </span>
                  </div>
                  <div className=" text-[12px] text-gray-700 mb-2 flex">
                    <h3 className="font-semibold pe-2 capitalize">Space :</h3>{" "}
                    {item.floor_Area} Sq. ft
                  </div>
                  {/* <div class="group my-3 inline-flex flex-wrap justify-center items-center gap-2">
                    <button
                      class="rounded-full pointer-events-none border border-slate-300 p-2.5 text-center text-sm transition-all shadow-sm hover:shadow-lg text-slate-600 hover:text-white hover:bg-slate-800 hover:border-slate-800 focus:text-white focus:bg-slate-800 focus:border-slate-800 active:border-slate-800 active:text-white active:bg-slate-800 disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
                      type="button"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="currentColor"
                        class="w-4 h-4"
                      >
                        <path d="M12 7.5a2.25 2.25 0 1 0 0 4.5 2.25 2.25 0 0 0 0-4.5Z" />
                        <path
                          fill-rule="evenodd"
                          d="M1.5 4.875C1.5 3.839 2.34 3 3.375 3h17.25c1.035 0 1.875.84 1.875 1.875v9.75c0 1.036-.84 1.875-1.875 1.875H3.375A1.875 1.875 0 0 1 1.5 14.625v-9.75ZM8.25 9.75a3.75 3.75 0 1 1 7.5 0 3.75 3.75 0 0 1-7.5 0ZM18.75 9a.75.75 0 0 0-.75.75v.008c0 .414.336.75.75.75h.008a.75.75 0 0 0 .75-.75V9.75a.75.75 0 0 0-.75-.75h-.008ZM4.5 9.75A.75.75 0 0 1 5.25 9h.008a.75.75 0 0 1 .75.75v.008a.75.75 0 0 1-.75.75H5.25a.75.75 0 0 1-.75-.75V9.75Z"
                          clip-rule="evenodd"
                        />
                        <path d="M2.25 18a.75.75 0 0 0 0 1.5c5.4 0 10.63.722 15.6 2.075 1.19.324 2.4-.558 2.4-1.82V18.75a.75.75 0 0 0-.75-.75H2.25Z" />
                      </svg>
                    </button>
                    <button
                      class="rounded-full pointer-events-none border border-slate-300 p-2.5 text-center text-sm transition-all shadow-sm hover:shadow-lg text-slate-600 hover:text-white hover:bg-slate-800 hover:border-slate-800 focus:text-white focus:bg-slate-800 focus:border-slate-800 active:border-slate-800 active:text-white active:bg-slate-800 disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
                      type="button"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="currentColor"
                        class="w-4 h-4"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M1.371 8.143c5.858-5.857 15.356-5.857 21.213 0a.75.75 0 0 1 0 1.061l-.53.53a.75.75 0 0 1-1.06 0c-4.98-4.979-13.053-4.979-18.032 0a.75.75 0 0 1-1.06 0l-.53-.53a.75.75 0 0 1 0-1.06Zm3.182 3.182c4.1-4.1 10.749-4.1 14.85 0a.75.75 0 0 1 0 1.061l-.53.53a.75.75 0 0 1-1.062 0 8.25 8.25 0 0 0-11.667 0 .75.75 0 0 1-1.06 0l-.53-.53a.75.75 0 0 1 0-1.06Zm3.204 3.182a6 6 0 0 1 8.486 0 .75.75 0 0 1 0 1.061l-.53.53a.75.75 0 0 1-1.061 0 3.75 3.75 0 0 0-5.304 0 .75.75 0 0 1-1.06 0l-.53-.53a.75.75 0 0 1 0-1.06Zm3.182 3.182a1.5 1.5 0 0 1 2.122 0 .75.75 0 0 1 0 1.061l-.53.53a.75.75 0 0 1-1.061 0l-.53-.53a.75.75 0 0 1 0-1.06Z"
                          clip-rule="evenodd"
                        />
                      </svg>
                    </button>
                    <button
                      class="rounded-full pointer-events-none border border-slate-300 p-2.5 text-center text-sm transition-all shadow-sm hover:shadow-lg text-slate-600 hover:text-white hover:bg-slate-800 hover:border-slate-800 focus:text-white focus:bg-slate-800 focus:border-slate-800 active:border-slate-800 active:text-white active:bg-slate-800 disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
                      type="button"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="currentColor"
                        class="w-4 h-4"
                      >
                        <path d="M11.47 3.841a.75.75 0 0 1 1.06 0l8.69 8.69a.75.75 0 1 0 1.06-1.061l-8.689-8.69a2.25 2.25 0 0 0-3.182 0l-8.69 8.69a.75.75 0 1 0 1.061 1.06l8.69-8.689Z" />
                        <path d="m12 5.432 8.159 8.159c.03.03.06.058.091.086v6.198c0 1.035-.84 1.875-1.875 1.875H15a.75.75 0 0 1-.75-.75v-4.5a.75.75 0 0 0-.75-.75h-3a.75.75 0 0 0-.75.75V21a.75.75 0 0 1-.75.75H5.625a1.875 1.875 0 0 1-1.875-1.875v-6.198a2.29 2.29 0 0 0 .091-.086L12 5.432Z" />
                      </svg>
                    </button>
                    <button
                      class="rounded-full pointer-events-none border border-slate-300 p-2.5 text-center text-sm transition-all shadow-sm hover:shadow-lg text-slate-600 hover:text-white hover:bg-slate-800 hover:border-slate-800 focus:text-white focus:bg-slate-800 focus:border-slate-800 active:border-slate-800 active:text-white active:bg-slate-800 disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
                      type="button"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="currentColor"
                        class="w-4 h-4"
                      >
                        <path d="M19.5 6h-15v9h15V6Z" />
                        <path
                          fill-rule="evenodd"
                          d="M3.375 3C2.339 3 1.5 3.84 1.5 4.875v11.25C1.5 17.16 2.34 18 3.375 18H9.75v1.5H6A.75.75 0 0 0 6 21h12a.75.75 0 0 0 0-1.5h-3.75V18h6.375c1.035 0 1.875-.84 1.875-1.875V4.875C22.5 3.839 21.66 3 20.625 3H3.375Zm0 13.5h17.25a.375.375 0 0 0 .375-.375V4.875a.375.375 0 0 0-.375-.375H3.375A.375.375 0 0 0 3 4.875v11.25c0 .207.168.375.375.375Z"
                          clip-rule="evenodd"
                        />
                      </svg>
                    </button>
                    <button
                      class="rounded-full pointer-events-none border border-slate-300 p-2.5 text-center text-sm transition-all shadow-sm hover:shadow-lg text-slate-600 hover:text-white hover:bg-slate-800 hover:border-slate-800 focus:text-white focus:bg-slate-800 focus:border-slate-800 active:border-slate-800 active:text-white active:bg-slate-800 disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
                      type="button"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="currentColor"
                        class="w-4 h-4"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M12.963 2.286a.75.75 0 0 0-1.071-.136 9.742 9.742 0 0 0-3.539 6.176 7.547 7.547 0 0 1-1.705-1.715.75.75 0 0 0-1.152-.082A9 9 0 1 0 15.68 4.534a7.46 7.46 0 0 1-2.717-2.248ZM15.75 14.25a3.75 3.75 0 1 1-7.313-1.172c.628.465 1.35.81 2.133 1a5.99 5.99 0 0 1 1.925-3.546 3.75 3.75 0 0 1 3.255 3.718Z"
                          clip-rule="evenodd"
                        />
                      </svg>
                    </button>
                  </div> */}
                  {/* View item Button */}
                <Link to={`/franchisedetail/${item?.franchise_name.replace(/ /g, "_")}/${item?._id}`}>
                  <button className="mt-4 bg-indigo-500 text-sm text-white py-2 px-4 rounded hover:bg-indigo-600 w-full">
                    View item
                  </button></Link>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </SwiperContainer>

        {/* Custom Navigation Buttons */}
        <div className="absolute top-1/2 transform -translate-y-1/2 -left-6 z-10">
          <button
            className="bg-gray-200 rounded-lg shadow-md px-4 py-2 hover:bg-gray-300 hover:text-white transition-all duration-300 ease-in-out"
            onClick={() => swiperRef.current.swiper.slidePrev()}
          >
            ❮
          </button>
        </div>
        <div className="absolute top-1/2 transform -translate-y-1/2 -right-6 z-10">
          <button
            className="bg-gray-200 rounded-lg shadow-md px-4 py-2 hover:bg-gray-300 hover:text-white transition-all duration-300 ease-in-out"
            onClick={() => swiperRef.current.swiper.slideNext()}
          >
            ❯
          </button>
        </div>
      </div>
    </section>
  );
};

export default PremiumOpportunities;
