import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Spinner from "./Spinner";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  clearAuthErrors,
  clearAuthMessage,
  loginUser,
} from "../redux/authSlice";
import SuccessAlert from "./SuccessAlert";
import ErrorAlert from "./ErrorAlert";
import Footer from "../HomeFiels/Comman/Footer";
import Header from "../HomeFiels/Comman/Header";
import SwiperSlider from "../AdsComponents/SwiperSlider";
import { Ads } from "../AdsComponents/Ads";

export default function Login() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [values, setValues] = useState({
    email: "",
    password: "",
    role: "",
  });
  const { auth, auth_loading, auth_error, auth_message } = useSelector(
    (state) => state.auth
  );

  useEffect(() => {
    if (auth_error) {
      const errorInterval = setInterval(() => {
        dispatch(clearAuthErrors());
      }, 3000);
      return () => clearInterval(errorInterval);
    }

    if (auth_message) {
      const messageInterval = setInterval(() => {
        dispatch(clearAuthMessage());
      }, 3000);
      return () => clearInterval(messageInterval);
    }
    if (auth?.role == "user" || auth?.role == "investor") {
      navigate(`/${auth?.role}/profile`);
    }
  }, [values.pincode, auth_message, auth_error, auth?.role]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(loginUser(values)).then(() => {
      setValues({
        email: "",
        password: "",
        role: "",
      });
    });
  };
  return (
    <>
      {auth_message && <SuccessAlert message={auth_message} />}
      {auth_error && <ErrorAlert error={auth_error} />}

      <Header />
      <div className="max-w-7xl mx-10 overflow-x-auto mt-6">
        <div className="w-full  lg:pl-0 mb-4 my-3">
          <div className="flex justify-between text-sm  ">
            <div className="text-gray-600 flex items-center pb-2 lg:pr-2  border-b-2 border-gray-600 capitalize">
              <h2 className="font-semibold  lg:text-2xl sm:text-xl text-lg text-gray-800 inline-block">
              Login Page
              </h2>
            </div>
          </div>
        </div>
        <div className="">
          <p className="md:text-base mb-2 text-justify lg:pr-6">
          At Way 4 Franchise, we’re committed to providing valuable resources for aspiring franchise owners and seasoned investors alike. By logging into your account, you can access a wealth of information, including.
          </p>
          {/* <div className="flex justify-start items-center">
            <span className="font-medium">Franchise Listings : </span>
            <p className="md:text-base text-justify lg:pr-6">Browse our extensive directory of franchise opportunities tailored to your interests.
            </p>
          </div>
          <div className="flex justify-start items-center">
            <span className="font-medium">Investor Plans : </span>
            <p className="md:text-base text-justify lg:pr-6">Explore our tiered investment strategies designed to elevate your franchise journey.
            </p>
          </div>
          <div className="flex justify-start items-center">
            <span className="font-medium">Franchise Listings : </span>
            <p className="md:text-base text-justify lg:pr-6">Browse our extensive directory of franchise opportunities tailored to your interests.
            </p>
          </div>
          <div className="flex justify-start items-center">
            <span className="font-medium">Newsletters : </span>
            <p className="md:text-base text-justify lg:pr-6">Stay updated with the latest trends, tips, and insights in the franchising world.
            </p>
          </div> */}
        </div>
      </div>
      <div class="grid grid-cols-1 sm:grid-cols-12 gap-4  max-w-full lg:mx-10 mx-4  mt-5">
        <div class="lg:col-span-4 col-span-12  border my-4">
          {/* <div className="grid grid-cols-6  border shadow-sm">
            banner
          </div>
          <div className="grid grid-cols-6 border shadow-sm">
            banner
          </div> */}
          {/* <SwiperSlider /> */}
          <div>
            {/* <img src="/loginads2.png" className=""/> */}
            <Ads adnum={3}/>
          </div>
        </div>

        <div class="lg:col-span-4  col-span-12  border shadow-sm rounded-sm my-4">
          <div className="bg-gray-100 px-4 py-2">
            <h2 className="text-lg text-center uppercase font-bold leading-9 tracking-tight text-gray-800">
              Sign in to your account
            </h2>
          </div>
          <form
            onSubmit={handleSubmit}
            method="POST"
            className="space-y-4  p-4"
          >
            <div className="grid grid-cols-12 gap-4 mt-3">
              <div className="col-span-6 flex items-center justify-start rounded-md border border-gray-500 shadow-sm p-1.5">
                <label htmlFor="investor" className="mr-2">
                  Investor
                </label>
                <input
                  id="investor"
                  type="radio"
                  name="role"
                  value="investor"
                  onChange={handleChange}
                  className="ml-auto"
                />
              </div>

              <div className="col-span-6 flex items-center justify-start rounded-md border border-gray-500 shadow-sm p-1.5">
                <label htmlFor="user" className="mr-2">
                  User
                </label>
                <input
                  id="user"
                  type="radio"
                  name="role"
                  value="user"
                  onChange={handleChange}
                  className="ml-auto"
                />
              </div>
            </div>

            <div>
              <label
                htmlFor="email"
                className="block font-medium leading-6 text-[13px] text-gray-900"
              >
                Email address
              </label>
              <div className="mt-2">
                <input
                  type="email"
                  name="email"
                  value={values.email}
                  onChange={handleChange}
                  require
                  placeholder="Enter your Email"
                  className="block w-full rounded-md border border-gray-500 text-[13px] text-gray-900 shadow-sm py-2 px-3 focus:ring-2 focus:ring-indigo-500"
                />
              </div>
            </div>

            <div>
              <label
                htmlFor="password"
                className="block font-medium leading-6 text-[13px] text-gray-900"
              >
                Password
              </label>
              <div className="mt-2">
                <input
                  name="password"
                  placeholder="Enter your Password"
                  value={values.password}
                  onChange={handleChange}
                  required
                  className="block w-full text-[13px] text-gray-900 rounded-md border border-gray-500 shadow-sm py-2 px-3 focus:ring-2 focus:ring-indigo-500"
                  type="password"
                />
              </div>
            </div>

            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <input
                  id="remember-me"
                  name="remember-me"
                  type="checkbox"
                  className="h-4 w-4 rounded border-gray-900 text-indigo-600"
                />
                <label
                  htmlFor="remember-me"
                  className="ml-3 block leading-6 text-[13px] text-gray-900"
                >
                  Remember me
                </label>
              </div>

              <div className="leading-6">
                <a
                  href="#"
                  className="font-semibold text-[13px] text-indigo-600 hover:text-indigo-500"
                >
                  Forgot password?
                </a>
              </div>
            </div>

            <div>
              <button
                type="submit"
                className="flex w-full justify-center text-[13px] rounded-md bg-indigo-600 px-4 py-2 font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-600"
              >
                {auth_loading ? <Spinner /> : "Sign in"}
              </button>
              <div className="text-center text-sm my-4">
                <p>
                  Don’t have an account yet?{" "}
                  <Link
                    to="user/registration"
                    className="text-indigo-600 font-semibold hover:underline"
                  >
                    Sign up
                  </Link>
                </p>
              </div>
            </div>
          </form>
        </div>

        <div class="lg:col-span-4 col-span-12 border  my-4">
          {/* <SwiperSlider /> */}
          <div className="">
            {/* <img src="/loginads1.png" className="h-full"/> */}
            <Ads adnum={3}/>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}
