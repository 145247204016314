import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {  getContainer } from "../../redux/homeManageSlice";
import { Ads } from "../../AdsComponents/Ads";

const btn = ["bg-blue-900 ", "bg-green-700", "bg-purple-700", "bg-yellow-700"];

const businesses = [
  {
    id: 1,
    img: "/cybersecurity.png",
  },
  {
    id: 2,
    img: "/adsway.png",
  },

];

const LeadingFranchise = () => {
  const [isHovered, setIsHovered] = useState(false);
  const [hoveredIndex, setHoveredIndex] = useState();
  const [data, setData] = useState();
  const [data2, setData2] = useState();
  const dispatch = useDispatch();
  const { containers, error, message,loading } = useSelector(
    (state) => state.homemanage
  );

  useEffect(() => {
    dispatch(getContainer());
  }, [dispatch,setData,setData2]);

  useEffect(() => {
    if (containers) {
      const filteredData1 = containers.filter(item => item.container === 1)
        .map(item => item?.franchiseDetails);
      setData(filteredData1);
      const filteredData2 = containers.filter(item => item.container === 2)
        .map(item => item?.franchiseDetails); 
      setData2(filteredData2);
    }
  }, [containers]);
  

  function HandleHover(bool, ind) {
    setIsHovered(bool);
    setHoveredIndex(ind);
  }
 


  return (
    <>
      <div className="max-w-full lg:mx-10 sm:mx-10 mx-2 py-10">
        <div className="grid grid-cols-12 px-3 gap-3">
          <div className="lg:col-span-9 sm:col-span-12 col-span-12 mt-2 border py-4 rounded-md  shadow-sm">
            <div className="w-full  border-b pb-3  px-4 mb-4">
              <div className="flex justify-between text-sm ">
                <div className="text-gray-800 flex items-center pb-2 pr-2  capitalize">
                  <h2 className="font-semibold  lg:text-2xl sm:text-xl text-lg text-gray-800 capitalize inline-block">
                    Best Franchises Business Opportunities
                  </h2>
                </div>
              </div>
            </div>

            {loading ? (
              <div className="flex items-center justify-center">
              <img src="/loading.gif" className="h-20 w-20"/>
              </div>
            ) : (
              <div className="max-w-full grid lg:grid-cols-4 sm:grid-cols-2 grid-cols-1">
                {data?.map((item, index) => (
                    <div key={item.index} className="w-full px-2 mb-5 mt-3 ">
                      <div className="bg-white rounded-md p-2  transform shadow-md hover:translate-y-2 hover:shadow-xl  transition duration-300 border">
                        <figure className="mb-2">
                          <img
                            src={
                              item?.companyLogo
                                ? `/uploads/InvestorData/${item?.companyLogo}`
                                : "/defaultlogo.png"
                            }
                            alt={item.title}
                            className="h-40 w-full"
                            // mx-auto object-contain
                          />
                        </figure>

                        <div className={`rounded-lg  py-2 flex flex-col `}>
                          <div className="flex justify-between">
                            <span className="text-xs text-gray-800 capitalize">
                              {" "}
                              {item?.looking_for}
                            </span>
                            <span className="text-xs text-gray-800 mt-[0.5px] capitalize">
                              {item?.currentOutlets}
                            </span>
                          </div>
                          <h5 className=" text-base font-semibold mt-[0.5px] capitalize">
                            {item?.franchise_name}
                          </h5>
                          <span className="text-xs text-gray-500 mt-[0.5px] capitalize">
                            {item?.floor_Area} sq.ft
                          </span>
                          <div className=" items-center">
                            <span className="text-[11px] text-green-600 font-medium capitalize">
                              {item?.investment_range_tier_1} for Tier 1 cities
                            </span>
                          </div>
                          <div className=" items-center">
                            <span className="text-[11px] text-green-600 font-medium capitalize">
                              {item?.investment_range_tier_2} for Tier 2 cities
                            </span>
                          </div>
                        </div>
                        <div className="relative">
                          <Link
                            to={`/franchisedetail/${item.franchise_name.replace(
                              " ",
                              "_"
                            )}/${item?._id}`}
                          >
                            <button
                              className={`rounded-full text-white ${
                                btn[index % btn.length]
                              } hover:shadow-xl focus:outline-none w-10 h-10 flex items-center justify-center ml-auto transition-all duration-300 transform ${
                                isHovered ? "hover:w-full" : ""
                              }`}
                              onMouseEnter={() => HandleHover(true, index)}
                              onMouseLeave={() => HandleHover(false, null)}
                            >
                              {hoveredIndex == index ? (
                                <span
                                  className="absolute left-0 right-0 text-center opacity-1 transition-opacity duration-300"
                                  // style={{ opacity: isHovered  ? "1" : "0" }}
                                >
                                  Know More
                                </span>
                              ) : (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="w-6 h-6 transition-opacity duration-300"
                                  stroke="currentColor"
                                  viewBox="0 0 24 24"
                                  // style={{
                                  //   opacity: !isHovered ? "1" : "0", // Show icon on non-hover
                                  //   transition: "opacity 0.3s ease",
                                  // }}
                                >
                                  <line x1="12" y1="5" x2="12" y2="19" />
                                  <line x1="5" y1="12" x2="19" y2="12" />
                                </svg>
                              )}
                            </button>
                          </Link>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            )}
          </div>
          <div className="lg:col-span-3 sm:col-span-12  col-span-12 mt-2 border rounded-md  shadow-sm">
            <div className="card">
              {/* <div className="relative ">
                <img src="/probanner.png" className="h-96 w-full" />
                <div className="text-xs absolute -top-3 -right-3 bg-black px-4 py-2 text-white mt-3 mr-3 hover:bg-white  transition duration-500 ease-in-out">
                  Ad
                </div>
              </div> */}
              <div className="px-4 pt-4">
              <Ads adnum={3}/>
              </div>

              <div className="card-body">
                <div className="card-title px-4 pb-4">
                  <h3 className="font-semibold text-base">
                    New Lounching Product
                  </h3>
                  <p className="text-gray-600 text-xs">
                    A product description is a form of marketing copy used to
                    describe and explain the benefits of your product
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="max-w-full sm:mx-10  lg:mx-10 mx-2 py-5">
        <div className="grid grid-cols-12 px-3 gap-2">
          <div className="lg:col-span-9 sm:col-span-12 col-span-12 mt-2 border py-4 rounded-md mb-8 shadow-sm">
            <div className="w-full  border-b pb-3  px-4 mb-4">
              <div className="flex justify-between text-sm ">
                <div className="text-gray-800 flex items-center pb-2 pr-2  capitalize">
                  <h2 className="font-semibold lg:text-2xl sm:text-xl text-lg text-gray-800 inline-block">
                    Latest Franchises Business Opportunities
                  </h2>
                </div>
              </div>
            </div>

            <div className="max-w-full grid lg:grid-cols-4 sm:grid-cols-2 grid-cols-1">
              {data2?.map((item, index) => (
                  <div key={item.index} className="w-full px-2 mb-5 mt-3 ">
                    <div className="bg-white rounded-md p-2  transform shadow-md hover:translate-y-2 hover:shadow-xl  transition duration-300 border">
                      <figure className="mb-2">
                        <img
                          src={
                            item?.companyLogo
                              ? `/uploads/InvestorData/${item?.companyLogo}`
                              : "/defaultlogo.png"
                          }
                          alt={item.title}
                          className="h-40 w-full"
                          // mx-auto object-contain
                        />
                      </figure>

                      <div className={`rounded-lg  py-2 flex flex-col `}>
                        <div className="flex justify-between">
                          <span className="text-xs text-gray-800 capitalize">
                            {" "}
                            {item?.looking_for}
                          </span>
                          <span className="text-xs text-gray-800 mt-[0.5px] capitalize">
                            {item?.currentOutlets}
                          </span>
                        </div>
                        <h5 className=" text-base font-semibold mt-[0.5px] capitalize">
                          {item?.franchise_name}
                        </h5>
                        <span className="text-xs text-gray-500 mt-[0.5px] capitalize">
                          {item?.floor_Area} sq.ft
                        </span>
                        <div className=" items-center">
                          <span className="text-[11px] text-green-600 font-medium capitalize">
                            {item?.investment_range_tier_1} for Tier 1 cities
                          </span>
                        </div>
                        <div className=" items-center">
                          <span className="text-[11px] text-green-600 font-medium capitalize">
                            {item?.investment_range_tier_2} for Tier 2 cities
                          </span>
                        </div>
                      </div>
                      <div className="relative">
                        <Link
                          to={`/franchisedetail/${item.franchise_name.replace(
                            " ",
                            "_"
                          )}/${item?._id}`}
                        >
                          <button
                            className={`rounded-full text-white ${
                              btn[index % btn.length]
                            } hover:shadow-xl focus:outline-none w-10 h-10 flex items-center justify-center ml-auto transition-all duration-300 transform ${
                              isHovered ? "hover:w-full" : ""
                            }`}
                            onMouseEnter={() => HandleHover(true, index)}
                            onMouseLeave={() => HandleHover(false, null)}
                          >
                            {hoveredIndex == index ? (
                              <span
                                className="absolute left-0 right-0 text-center opacity-1 transition-opacity duration-300"
                                // style={{ opacity: isHovered  ? "1" : "0" }}
                              >
                                Know More
                              </span>
                            ) : (
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="w-6 h-6 transition-opacity duration-300"
                                stroke="currentColor"
                                viewBox="0 0 24 24"
                                // style={{
                                //   opacity: !isHovered ? "1" : "0", // Show icon on non-hover
                                //   transition: "opacity 0.3s ease",
                                // }}
                              >
                                <line x1="12" y1="5" x2="12" y2="19" />
                                <line x1="5" y1="12" x2="19" y2="12" />
                              </svg>
                            )}
                          </button>
                        </Link>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
          <div className="lg:col-span-3 sm:col-span-12  col-span-12 my-2 border py-2 rounded-md mb-8 shadow-sm">
            <div className="">
              <h3 className="px-5 mb-3 mt-2 capitalize text-lg font-semibold ">
                Best Business Opportunities{" "}
              </h3>
              <div className="grid grid-cols-1 gap-y-4 px-5 ">
                {/* {businesses.map((business) => (
                  <div
                    key={business.id}
                    className="relative bg-white border shadow-sm rounded-md overflow-hidden hover:shadow-xl transition-all duration-300"
                  >
                    {business.name === "FranchiseBazar" ? (
                      <div className="absolute inset-0 bg-yellow-600 text-white flex flex-col justify-center items-center">
                        <h3 className="font-bold">{business.name}</h3>
                        <p>{business.investment}</p>
                      </div>
                    ) : (
                      <> */}
                        <Ads adnum={2}/>
                        <Ads adnum={2}/>
                      {/* </> */}
                    {/* )} */}
                  {/* </div> */}
                {/* ))} */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LeadingFranchise;
