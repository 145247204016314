import {Link} from "react-router-dom" 
import React, { useState, useEffect } from "react";
import { getAllFranchise } from "../../redux/franchiseSlice";
import { useDispatch, useSelector } from "react-redux";

const TopInternational = () => {
  const [isHovered, setIsHovered] = useState(false);
  const [hoveredIndex, setHoveredIndex] = useState();
  const dispatch = useDispatch();
  const { allfranchise, loading, error, message } = useSelector(
    (state) => state.franchise
  );

  useEffect(() => {
    dispatch(getAllFranchise());
  }, []);
  function HandleHover(bool, ind) {
    setIsHovered(bool);
    setHoveredIndex(ind);
  }
  return (
    <div className="max-w-full mx-10 mt-8 px-4">
      <div className="w-full pl-5 lg:pl-2 mb-4  px-2">
          <div className="border-b  flex justify-between text-sm ">
            <div className="text-indigo-600 flex items-center pb-2 pr-2 border-b-2 border-indigo-600 capitalize">
              <h2 className="font-semibold  lg:text-2xl sm:text-xl text-lg text-gray-800 inline-block">
              Top Dealership Opportunity
              </h2>
            </div>
            <Link to="/viewall?heading=Top Dealership Opportunity" className="text-base text-blue-500">
              See All
            </Link>
          </div>
        </div>
      

      {/* Tiles Section */}
      <div className="grid xl:grid-cols-3 lg:grid-cols-3 md:grid-cols-1 sm:grid-cols-1 gap-8">
        {allfranchise?.slice(8,11)?.map((item, index) => (
          <div key={index} className="rounded-lg">
            {/* Image Section */}
            <div className="relative ">
              <img
                 src={
                  item?.companyLogo
                    ? `/uploads/InvestorData/${item?.companyLogo}`
                    : "/defaultlogo.png"
                }
                alt="Image 1"
                className="w-full h-48  object-cover rounded-lg transition-transform transform scale-100 hover:scale-105"
              />
            </div>

            {/* Content Section */}
            <div className="px-4 sm:px-10 relative -mt-12">
              <div className="bg-white custom-shadow transition duration-700 ease-in-out rounded-lg p-6 relative">
                {/* Logo */}
                <div className="absolute shadow-lg -top-16 left-1/3 transform -translate-x-1/2 p-3 border-8 border-white rounded-full bg-[#d17e7e]">
                  <img
                     src={
                      item?.companyLogo
                        ? `/uploads/InvestorData/${item?.companyLogo}`
                        : "/defaultlogo.png"
                    }
                    className="rounded-full w-24 h-24 object-cover"
                    alt="Dealership"
                  />
                </div>
                {/* Text Content */}
                <div className="flex flex-col items-start mt-16">
                  <p className="text-[12px]">{item?.industry}</p>
                  <h4 className="text-md font-semibold">{item?.franchise_name}</h4>
                  <p className="text-[13px]">{item?.investment_range_tier_1}</p>
                  <p className="text-[13px]">{item?.investment_range_tier_2}</p>
                  <Link to={`/franchisedetail/${item.franchise_name.replace(
                            " ",
                            "_"
                          )}/${item?._id}`}
                    className="p-2 leading-none w-full text-center rounded mt-3 text-gray-900 font-semibold bg-gray-900 text-white text-[13px]  py-3  uppercase"
                    href="#"
                  >
                    Know More
                  </Link>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TopInternational;
