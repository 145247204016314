import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Dialog } from "@headlessui/react";
import { clearErrors, clearMessage } from "../redux/leadUpdateSlice";
import SuccessAlert from "../BaseFile/SuccessAlert";
import ErrorAlert from "../BaseFile/ErrorAlert";
import { RiAdvertisementFill } from "react-icons/ri";
import {uplaodAd} from "../redux/adSlice"

export default function RunAd({ closeModel, id }) {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(true);
  const [adText, setAdText] = useState("");
  const [adLink, setAdLink] = useState("");
  const [adType, setAdType] = useState("");
  const [amount, setAmount] = useState("");
  const [days, setDays] = useState("");
  const [errorMessages, setErrorMessages] = useState({});
  const [images, setImages] = useState({
    image1: {
      file: null,
      minwidth: 300,
      minheight: 780,
      maxwidth: 350,
      maxheight: 810,
    },
    image2: {
      file: null,
      minwidth: 360,
      minheight: 210,
      maxwidth: 400,
      maxheight: 250,
    },
    image3: {
      file: null,
      minwidth: 380,
      minheight: 410,
      maxwidth: 400,
      maxheight: 450,
    },
    image4: {
      file: null,
      minwidth: 1400,
      minheight: 385,
      maxwidth: 1450,
      maxheight: 410,
    },
  });
  const { error, message } = useSelector((state) => state.ad);

  const handleImageUpload = (e, key) => {
    const file = e.target.files[0];
    const { minwidth, minheight, maxwidth, maxheight } = images[key];

    if (file) {
      const img = new Image();
      img.src = URL.createObjectURL(file);
      img.onload = () => {
        if (
          img.width >= minwidth &&
          img.width <= maxwidth &&
          img.height >= minheight &&
          img.height <= maxheight
        ) {
          setImages((prev) => ({
            ...prev,
            [key]: { ...prev[key], file },
          }));
          setErrorMessages((prev) => ({ ...prev, [key]: null }));
        } else {
          setErrorMessages((prev) => ({
            ...prev,
            [key]: `Image dimensions must be between ${minwidth}x${minheight} and ${maxwidth}x${maxheight} pixels.`,
          }));
        }
      };
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (
      id &&
      adText &&
      adLink &&
      adType &&
      (adType === "click" ? amount : days) &&
      Object.values(images).every((img) => img.file)
    ) {
      const adData = {
        franchise_id: id,
        adText,
        adLink,
        adType,
        amount,
        days,
        images: Object.keys(images).reduce((acc, key) => {
          const { file} = images[key];
          acc[key] = {
            file
          };
          return acc;
        }, {}),
      };
  
      dispatch(uplaodAd(adData));
    } else {
      console.log("Please fill all required fields.");
    }
  };
  

  useEffect(() => {
    if (error) {
      const errorTimeout = setTimeout(() => dispatch(clearErrors()), 3000);
      return () => clearTimeout(errorTimeout);
    }
    if (message) {
      const messageTimeout = setTimeout(() => dispatch(clearMessage()), 3000);
      return () => clearTimeout(messageTimeout);
    }
  }, [dispatch, message, error]);

  return (
    <>
      {message && <SuccessAlert message={message} />}
      {error && <ErrorAlert error={error} />}
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        className="relative z-10"
      >
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

        <div className="fixed inset-0 flex items-center justify-center px-4">
          <Dialog.Panel className="w-full max-w-xl transform overflow-hidden rounded-lg bg-white shadow-xl transition-all">
            <div className="flex flex-col items-center p-6">
              <div className="flex items-center justify-center h-12 w-12 rounded-full bg-blue-100">
                <RiAdvertisementFill
                  className="h-6 w-6 text-blue-600"
                  aria-hidden="true"
                />
              </div>
              <div className="grid grid-cols-2 gap-3 mt-5">
                {[1, 2, 3, 4].map((num) => (
                  <div key={`image${num}`}>
                    <label
                      htmlFor={`image${num}`}
                      className="text-xs text-gray-900"
                    >
                      Upload Image {num} (Dimensions:
                      {images[`image${num}`].minwidth}x
                      {images[`image${num}`].minheight}
                      to {images[`image${num}`].maxwidth}x
                      {images[`image${num}`].maxheight})
                    </label>
                    <input
                      id={`image${num}`}
                      type="file"
                      accept="image/*"
                      onChange={(e) => handleImageUpload(e, `image${num}`)}
                      className="w-full mt-1 cursor-pointer appearance-none rounded-sm border border-gray-300 bg-white px-3 py-2 text-[13px] focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500"
                    />
                    {errorMessages[`image${num}`] && (
                      <p className="text-xs text-red-500 mt-1">
                        {errorMessages[`image${num}`]}
                      </p>
                    )}
                  </div>
                ))}

                <select
                  value={adType}
                  onChange={(e) => setAdType(e.target.value)}
                  className="w-full mt-4 rounded-sm border border-gray-300 p-2 text-[13px] focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500"
                >
                  <option value="" disabled>
                    Select Ad Type
                  </option>
                  <option value="click">Pay Per Click</option>
                  <option value="abc">abc</option>
                </select>

                {adType === "click" ? (
                  <input
                    type="number"
                    value={amount}
                    onChange={(e) => setAmount(e.target.value)}
                    placeholder="Enter Amount (Rs 5 per click)"
                    className="w-full mt-4 rounded-sm border border-gray-300 p-2 text-[13px] focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500"
                  />
                ) : (
                  <input
                    type="number"
                    value={days}
                    onChange={(e) => setDays(e.target.value)}
                    placeholder="Enter Days (Rs. 100 per day)"
                    className="w-full mt-4 rounded-sm border border-gray-300 p-2 text-[13px] focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500"
                  />
                )}
              </div>
              <input
                type="url"
                value={adLink}
                onChange={(e) => setAdLink(e.target.value)}
                placeholder="Enter Ad Link"
                className="w-full mt-4 rounded-sm border border-gray-300 p-2 text-[13px] focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500"
              />
              <textarea
                value={adText}
                onChange={(e) => setAdText(e.target.value)}
                placeholder="Enter Ad Description"
                className="w-full mt-4 rounded-sm border border-gray-300 p-2 text-[13px] focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500"
              />
            </div>

            <div className="my-5 px-6 sm:flex sm:justify-end">
              <button
                type="button"
                onClick={(e) => handleSubmit(e)}
                className="inline-flex w-full sm:w-auto justify-center rounded-md px-4 py-2 text-[13px] text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Rs. {adType == "click" ? amount : days * 100}
              </button>
              <button
                type="button"
                onClick={closeModel}
                className="mt-3 inline-flex w-full sm:w-auto justify-center text-[13px] rounded-md border border-gray-300 bg-white px-4 py-2 text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:ml-3"
              >
                Cancel
              </button>
            </div>
          </Dialog.Panel>
        </div>
      </Dialog>
    </>
  );
}
