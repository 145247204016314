import { useState, useRef, useEffect } from "react";
import { MdOutlineSecurity } from "react-icons/md";
import {
  Dialog,
  DialogBackdrop,
  DialogPanel,
  DialogTitle,
} from "@headlessui/react";
import { CheckIcon } from "@heroicons/react/24/outline";
import { useSelector, useDispatch } from "react-redux";

import { verifyOtp } from "../../redux/authSlice";
export default function OtpVerification({ open, setOpen ,email}) {
  const dispatch = useDispatch();
  const [otp, setOtp] = useState();
  const { auth_loading } = useSelector((state) => state.auth);
  const handleSubmit = (e) => {
    e.preventDefault();
    if(otp.length == 6){
    dispatch(verifyOtp(otp));
    setOpen();}
    else{
        window.alert("Enter 6 Digits OTP")
    }
  };

  return (
    <Dialog open={open} onClose={() => {}}  className="relative z-10">
      <DialogBackdrop
        transition
        className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity "
      />

      <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div className="flex min-h-lg lg:min-h-full lg:items-center items-center justify-center p-4 text-center sm:items-center sm:p-0">
          <DialogPanel
            transition
            className="relative top-28 lg:top-0 transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all  data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-md sm:p-6"
          >

    {auth_loading ? 
    <div className="flex justify-center items-center">
    <img src="/loading.gif" className="h-12" /> </div>: (
            <>
            <div>
              <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
                <MdOutlineSecurity  aria-hidden="true" className="h-6 w-6 text-green-600" />
              </div>
              <div className="mt-3 text-center sm:mt-5">
                {/* <DialogTitle as="h3" className="text-base font-semibold leading-6 text-gray-900">
                  Otp Send to  {values.email}
                </DialogTitle> */}
                <header className="mb-5">
              <h1 className="text-2xl font-bold mb-1">OTP  Verification</h1>
              <p className="text-[15px] text-gray-600">
                Enter the 6-digit verification code that was sent to your.</p>
                <span className="font-semibold text-gray-500">{email}.</span> 
            </header>
                <div className="mt-0 ">
                  <input maxLength={6} minLength={6} type='number' name='otp'className='border border-gray-400 rounded-sm pl-3 py-1 text-center text-sm'   required onChange = {(e)=>setOtp(e.target.value)}/>
                </div>
              </div>
            </div>
            <div className="flex gap-3 mt-5 sm:mt-6 px-8">
              <button
                type="submit"
                onClick={setOpen}
                className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
              >
                Close
              </button>
              <button
                type="submit"
                onClick={(e)=>handleSubmit(e)}
                className="inline-flex w-full justify-center rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
              >
                Verify OTP
              </button>
            </div></>)}
          </DialogPanel>
        </div>
      </div>
    </Dialog>
  );
}
