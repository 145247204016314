import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect } from "react";
import { getInvestorLeadById } from "../redux/leadUpdateSlice";
import {
  EditFranchiseProfile,
  clearErrors,
  clearMessage,
} from "../redux/franchiseSlice";
import { getFranchiseByid } from "../redux/franchiseSlice";
import RechargeWallet from "./RechargeWallet";
import BuyLeadModel from "./BuyLeadModel";
import { getHomeDetailByid } from "../redux/homeManageSlice";
import InvestorClient from "./InvestorClient";
import { VscUnverified } from "react-icons/vsc";
import { MdVerified } from "react-icons/md";
import InvestorKycDetail from "./InvestorKycDetail";
import InvestorPlan from "./InvestorPlan";
import InvestorLeads from "./InvestorLeads";
import InvestorTrans from "./InvestorTrans";
import InvestorUpload from "./InvestorUpload";
import SuccessAlert from "../BaseFile/SuccessAlert";
import ErrorAlert from "../BaseFile/ErrorAlert";
import MarketingDetails from "./MarketingDetails";
import DescriptionForm from "./DescriptionForm";
import { GiClick } from "react-icons/gi";
import { MdWatchLater } from "react-icons/md";
import { SlWallet } from "react-icons/sl";
import { RiUserShared2Fill } from "react-icons/ri";
import RunAd from "./RunAd";
import { FaEye } from "react-icons/fa";
import Investorads from "./Investorads";
import { getAdsByid } from "../redux/adSlice";
const tabs = [
  { name: "DASHBOARD", href: "#", current: false },
  { name: "CLIENTS", href: "#", current: false },
  { name: "PROFILE", href: "#", current: true },
  { name: "FRANCHISE", href: "#", current: false },
  { name: "BUSINESS", href: "#", current: false },
  { name: "TRAINING", href: "#", current: false },
  { name: "PROPERTY", href: "#", current: false },
  { name: "KYC", href: "#", current: false },
  { name: "TRAN", href: "#", current: false },
  // { name: "PLAN", href: "#", current: false },
  { name: "UPLOADS", href: "#", current: false },
  { name: "MARKETING", href: "#", current: false },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
const AdminFranchiseeProfile = () => {
  const dispatch = useDispatch();
  const { singleuser } = useSelector((state) => state.allusers);
  const { HomeDetail } = useSelector((state) => state.homemanage);
  const { franchisee, loading, error, message } = useSelector(
    (state) => state.franchise
  );
  const { singleinvestorlead } = useSelector((state) => state.leads);
  const { investorad } = useSelector((state) => state.ad);
  const { auth } = useSelector((state) => state.auth);
  const [currentTab, setCurrentTab] = useState("DASHBOARD");
  const [openRechargeWalletModel, setOpenRechargeWalletModel] = useState(false);
  const [openBuyLeadModel, setOpenBuyLeadModel] = useState(false);
  const [openAdModel, setOpenAdModel] = useState(false);
  const [enableEdit, setEnableEdit] = useState(false);
  const [daysRemaining, setDaysRemaining] = useState(null);
  const [remainingTimeOutput, setRemainingTimeOutput] = useState("");
  const [values, setValues] = useState(false);
  const [totalamount, setTotalamount] = useState(false);
  const [totalCount, setTotalCount] = useState(false);

  useEffect(() => {
    if (auth?._id) {
      const _id = auth?._id;
      const id = auth?._id;
      dispatch(getFranchiseByid({ _id }));
      dispatch(getAdsByid(id));
      dispatch(getHomeDetailByid({ _id }));
      dispatch(getInvestorLeadById({ franchise_id: _id }));
    }
  }, [auth?._id, dispatch]);

  function handleEdit(tab) {
    setCurrentTab(tab);
    setEnableEdit(true);
  }

  useEffect(() => {
    if (error) {
      const errorInterval = setInterval(() => {
        dispatch(clearErrors());
      }, 3000);
      return () => clearInterval(errorInterval);
    }
    if (message) {
      const messageInterval = setInterval(() => {
        dispatch(clearMessage());
      }, 3000);
      return () => clearInterval(messageInterval);
    }
  }, [dispatch, message, error, clearErrors, clearMessage]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const updatedValue = { ...values };
    const userData = { _id: auth?._id, updatedValue: updatedValue };
    dispatch(EditFranchiseProfile(userData));
    setEnableEdit(false);
  };

  useEffect(() => {
    const updateRemainingTime = () => {
      const createdAtDate = new Date(HomeDetail?.created_At);
      const endDate = new Date(createdAtDate);
      endDate.setMonth(endDate.getMonth() + parseInt(HomeDetail?.time_period));
      const currentDate = new Date();
      const remainingTime = endDate - currentDate;
      const remainingDays = Math.floor(remainingTime / (1000 * 60 * 60 * 24));
      setDaysRemaining(remainingDays);

      setRemainingTimeOutput(getRemainingTime(remainingTime));
    };
    updateRemainingTime();

    // Set interval to update every second
    const intervalId = setInterval(updateRemainingTime, 1000);
    return () => clearInterval(intervalId);
  }, [HomeDetail]);

  const getRemainingTime = (remainingMilliseconds) => {
    if (remainingMilliseconds < 0) {
      return "Time is up!";
    }
    const totalSeconds = Math.floor(remainingMilliseconds / 1000);
    const years = Math.floor(totalSeconds / (365 * 24 * 3600));
    const months = Math.floor(
      (totalSeconds % (365 * 24 * 3600)) / (30 * 24 * 3600)
    );
    const days = Math.floor((totalSeconds % (30 * 24 * 3600)) / (24 * 3600));
    const hours = Math.floor((totalSeconds % (24 * 3600)) / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;

    return `${months}M : ${days}D : ${hours}H : ${minutes}M : ${seconds}S `;
  };

  function handlerechargemodel() {
    setOpenRechargeWalletModel(true);
  }

  function handleAdmodel() {
    setOpenAdModel(true);
  }

  function handleShowAd() {
    setCurrentTab("ads");
  }
  function handleBuyLeadmodel() {
    setOpenBuyLeadModel(true);
  }
  function closeModel() {
    setOpenRechargeWalletModel(false);
    setOpenBuyLeadModel(false);
    setOpenAdModel(false);
  }
  useEffect(() => {
    if (investorad) {
      setTotalCount(
        investorad.reduce((total, item) => total + (item.clickCount || 0), 0)
      );
      setTotalamount(
        investorad.reduce((total, item) => total + (item.amount || 0), 0)
      );
    }
  }, [investorad]);

  const cards = [
    {
      name: "Wallet",
      amount: `${franchisee?.wallet || 0} Rs`,
      detail: `Add Amount to your Wallet`,
      icon: SlWallet,
      func: handlerechargemodel,
      bgColor: "bg-green-100", // Lighter green for softer look
      iconColor: "text-green-500", // Softer green for icon
      detailColor: "text-green-700", // Darker color for detail text
    },
    {
      name: "Total Leads",
      amount: `${singleinvestorlead?.totalLeads || 0} Leads`,
      detail: `Buy leads`,
      icon: RiUserShared2Fill,
      func: handleBuyLeadmodel,
      bgColor: "bg-blue-100", // Light blue for background
      iconColor: "text-blue-500",
      detailColor: "text-blue-700",
    },
    {
      name: "Total Click",
      amount: `${totalCount || 0} Clicks`,
      detail: `Run Ad for Your Franchise`,
      icon: GiClick,
      bgColor: "bg-yellow-100",
      func: handleAdmodel,
      iconColor: "text-yellow-500",
      detailColor: "text-yellow-700",
    },
    {
      name: "Total Ad investment",
      amount: `${totalamount + totalCount * 5 || 0} Rs`,
      detail: `Show Ads Details`,
      icon: FaEye,
      func: handleShowAd,
      bgColor: "bg-pink-100",
      iconColor: "text-pink-500",
      detailColor: "text-pink-700",
    },
  ];
  return (
    <>
      {message && <SuccessAlert message={message} />}
      {error && <ErrorAlert error={error} />}
      <main>
        <div className="w-full  lg:mb-0 mb-20">
          <div className="">
            <div className=" flex lg:w-full">
              <div className="flex h-full w-full bg-white shadow-sm">
                <div className="divide-y divide-gray-200 w-full">
                  <div className="pb-6">
                    <div className="h-24 bg-gray-700 sm:h-20 lg:h-28 relative"></div>

                    <div className="-mt-12 flow-root px-4 sm:-mt-8 sm:flex sm:items-end sm:px-6 lg:-mt-16">
                      <div className="relative">
                        <div className="-m-1 flex">
                          <div className="inline-flex overflow-hidden rounded-lg border-4 border-white">
                            <img
                              alt=""
                              src={
                                franchisee?.companyLogo
                                  ? `/uploads/InvestorData/${franchisee?.companyLogo}`
                                  : "/defaultlogo.png"
                              }
                              className="h-24 w-24 flex-shrink-0 sm:h-40 sm:w-40 lg:h-48 lg:w-48"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="mt-6 sm:ml-6 sm:flex-1 w-full">
                        <div>
                          <div className="flex items-center">
                            <h3 className="text-xl font-bold text-gray-900 sm:text-2xl">
                              {franchisee?.franchise_name}
                            </h3>

                            {franchisee?.isVerified == "yes" ? (
                              <MdVerified className="text-green-500 text-2xl" />
                            ) : (
                              <VscUnverified className="text-red-500 text-2xl" />
                            )}
                          </div>
                          <p className="text-sm text-gray-500">
                            {" "}
                            {franchisee?.ownerEmail}
                          </p>
                        </div>
                      </div>
                      <div className="flex items-center">
                        <div className="">
                          {HomeDetail?.length == 0 ? (
                            <p className=" right-0 top-[40px] rounded-md  me-3 text-gray-800  text-sm shadow-sm p-3 capitalize">
                              no promotion plan activated
                            </p>
                          ) : (
                            <>
                              <div className="flex  items-center">
                                <div className="">
                                  <MdWatchLater
                                    className={`w-10 h-10 animate-spin ${
                                      daysRemaining <= 7
                                        ? "text-red-600"
                                        : "text-green-600"
                                    }`}
                                  />
                                </div>
                                <div className="  right-0 top-[23px] rounded-md  me-5 text-gray-800 font-medium text-sm shadow-sm p-3">
                                  <span className="capitalize text-gray-800  block ">
                                    {" "}
                                    promotion plan expired in
                                  </span>
                                  <span className=" ">
                                    {remainingTimeOutput}
                                  </span>
                                </div>
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="px-3 lg:px-0 py-3 lg:py-0 sm:px-0">
                    <div className="sm:hidden">
                      <label htmlFor="question-tabs" className="sr-only">
                        Select a tab
                      </label>
                      <select
                        id="question-tabs"
                        defaultValue={tabs.find((tab) => tab.current).name}
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900  shadow-sm text-sm font-semibold px-1 ring-1 ring-inset "
                      >
                        {tabs.map((tab) => (
                          <option
                            onClick={() => setCurrentTab(tab.name)}
                            key={tab.name}
                          >
                            {tab.name}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="hidden sm:block">
                      <nav
                        aria-label="Tabs"
                        className="isolate flex divide-x divide-gray-200 rounded-lg shadow overflow-auto "
                      >
                        {tabs.map((tab, tabIdx) => (
                          <button
                            key={tab.name}
                            href={tab.href}
                            aria-current={tab.current ? "page" : undefined}
                            onClick={() => setCurrentTab(tab.name)}
                            className={classNames(
                              tab.name == currentTab
                                ? "text-gray-900"
                                : "text-gray-500 hover:text-gray-700",
                              tabIdx === 0 ? "rounded-l-lg" : "",
                              tabIdx === tabs.length - 1 ? "rounded-r-lg" : "",
                              "group relative  flex-1 w-24 bg-white px-3 py-4 text-center text-[13px] font-medium hover:bg-gray-50 focus:z-10"
                            )}
                          >
                            <span>{tab.name}</span>
                            <span
                              aria-hidden="true"
                              className={classNames(
                                tab.name == currentTab
                                  ? "bg-rose-500"
                                  : "bg-transparent",
                                "absolute inset-x-0 bottom-0 h-0.5"
                              )}
                            />
                          </button>
                        ))}
                      </nav>
                    </div>
                  </div>

                  {/* <div className="max-w-7xl mt-12 px-4">
                    <div className=" grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 my-10">
                      {cards.map((card) => (
                        <div
                          key={card.name}
                          className="rounded-xl bg-white shadow-sm px-2 py-2 border"
                        >
                          <div className="rounded-sm  relative">
                            <div
                              className={`py-3 ${card.bgColor} w-[50px] flex justify-center items-center rounded-lg absolute -top-[25px] left-[18px]`}
                            >
                              <card.icon
                                aria-hidden="true"
                                className={`h-6 w-6 ${card.iconColor}`}
                              />
                            </div>
                            <div className="p-5">
                              <div className="flex items-center">
                                <div className="ml-5 w-0 flex-1 text-right">
                                  <dl>
                                    <dt className="truncate text-[12px] mt-1 tracking-wide text-gray-700">
                                      {card.name}
                                    </dt>
                                    <dd>
                                      <div className="text-xl font-medium text-gray-900">
                                        {card.amount}
                                      </div>
                                    </dd>
                                  </dl>
                                </div>
                              </div>
                            </div>

                            <div className="px-5 py-3 border-t rounded-xl">
                              <div className="text-xs">
                                <button
                                  onClick={card.func}
                                  className={`font-medium hover:text-gray-900 ${card.detailColor}`}
                                >
                                  {card.detail}
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div> */}

                  {currentTab == "CLIENTS" && <InvestorClient />}

                  {currentTab == "PROFILE" && (
                    <div className="px-4 py-5 sm:px-0 sm:py-0">
                      <dl className="space-y-8 sm:space-y-0 sm:divide-y sm:divide-gray-200">
                        <div className="flex justify-between items-center">
                          <div className="">
                            <h2
                              id="franchise"
                              className="text-base uppercase font-semibold leading-6 text-gray-900 border-b p-5"
                            >
                              PROFILE DETAILS
                            </h2>
                          </div>
                          <div className="">
                            {enableEdit ? (
                              <>
                                <button
                                  onClick={(e) => handleSubmit(e)}
                                  className="mr-5 hover:bg-indigo-700 bg-indigo-600 w-20 py-1 rounded-md text-white "
                                >
                                  {" "}
                                  Save
                                </button>
                                <button
                                  onClick={(e) => setEnableEdit(false)}
                                  className="mr-5  hover:bg-red-700 bg-red-600 w-20 py-1 rounded-md text-white"
                                >
                                  {" "}
                                  Cancel
                                </button>
                              </>
                            ) : (
                              <button
                                onClick={(e) => handleEdit("PROFILE")}
                                className="mr-5  bg-indigo-600 hover:bg-indigo-700 w-20 py-1 rounded-md text-white "
                              >
                                {" "}
                                Edit
                              </button>
                            )}
                          </div>
                        </div>
                        {/* <div className="sm:flex sm:px-6 sm:py-5">
                          <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                          PROFILE
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:ml-6 sm:mt-0">
                            <p>
                              Enim feugiat ut ipsum, neque ut. Tristique mi id
                              elementum praesent. Gravida in tempus feugiat
                              netus enim aliquet a, quam scelerisque. Dictumst
                              in convallis nec in bibendum aenean arcu.
                            </p>
                          </dd>
                        </div> */}
                        <div className="sm:flex sm:px-6 sm:py-5">
                          <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                            Owner Name
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:ml-6 sm:mt-0">
                            {enableEdit && currentTab == "PROFILE" ? (
                              <input
                                id="ownerName"
                                name="ownerName"
                                type="text"
                                defaultValue={franchisee?.ownerName}
                                onChange={handleChange}
                                placeholder="ownerName"
                                className="block w-full rounded-full border-0 px-4 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                              />
                            ) : (
                              franchisee?.ownerName
                            )}
                          </dd>
                        </div>
                        <div className="sm:flex sm:px-6 sm:py-5">
                          <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                            Manager Name
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:ml-6 sm:mt-0">
                            {enableEdit && currentTab == "PROFILE" ? (
                              <input
                                id="managerName"
                                name="managerName"
                                defaultValue={franchisee?.managerName}
                                type="text"
                                onChange={handleChange}
                                placeholder="managerName"
                                className="block w-full rounded-full border-0 px-4 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                              />
                            ) : (
                              franchisee?.managerName
                            )}
                          </dd>
                        </div>
                        <div className="sm:flex sm:px-6 sm:py-5">
                          <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                            Email
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:ml-6 sm:mt-0">
                            <time dateTime="1982-06-23">
                              {franchisee?.ownerEmail}
                            </time>
                          </dd>
                        </div>
                        <div className="sm:flex sm:px-6 sm:py-5">
                          <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                            Manager Email
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:ml-6 sm:mt-0">
                            <time dateTime="1982-06-23">
                              {enableEdit && currentTab == "PROFILE" ? (
                                <input
                                  id="managerEmail"
                                  name="managerEmail"
                                  type="text"
                                  defaultValue={franchisee?.managerEmail}
                                  onChange={handleChange}
                                  placeholder="managerEmail"
                                  className="block w-full rounded-full border-0 px-4 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                              ) : (
                                franchisee?.managerEmail
                              )}
                            </time>
                          </dd>
                        </div>
                        <div className="sm:flex sm:px-6 sm:py-5">
                          <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                            Phone No.
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:ml-6 sm:mt-0">
                            <time dateTime="1982-06-23">
                              {franchisee?.phone}
                            </time>
                          </dd>
                        </div>
                        <div className="sm:flex sm:px-6 sm:py-5">
                          <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                            Manager Phone No.
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:ml-6 sm:mt-0">
                            <time dateTime="1982-06-23">
                              {enableEdit && currentTab == "PROFILE" ? (
                                <input
                                  id="managerPhone"
                                  name="managerPhone"
                                  type="number"
                                  defaultValue={franchisee?.managerPhone}
                                  onChange={handleChange}
                                  placeholder="managerPhone"
                                  className="block w-full rounded-full border-0 px-4 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                              ) : (
                                franchisee?.managerPhone
                              )}
                            </time>
                          </dd>
                        </div>
                        <div className="sm:flex sm:px-6 sm:py-5">
                          <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                            Pin Code
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:ml-6 sm:mt-0">
                            <time dateTime="1982-06-23">
                              {enableEdit && currentTab == "PROFILE" ? (
                                <input
                                  id="pincode"
                                  name="pincode"
                                  type="number"
                                  defaultValue={franchisee?.pincode}
                                  onChange={handleChange}
                                  placeholder="pincode"
                                  className="block w-full rounded-full border-0 px-4 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                              ) : (
                                franchisee?.pincode
                              )}
                            </time>
                          </dd>
                        </div>
                        <div className="sm:flex sm:px-6 sm:py-5">
                          <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                            Address
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:ml-6 sm:mt-0">
                            <time dateTime="1982-06-23">
                              {enableEdit && currentTab == "PROFILE" ? (
                                <input
                                  id="address"
                                  name="address"
                                  type="text"
                                  defaultValue={franchisee?.address}
                                  onChange={handleChange}
                                  placeholder="address"
                                  className="block w-full rounded-full border-0 px-4 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                              ) : (
                                franchisee?.address
                              )}
                            </time>
                          </dd>
                        </div>
                        <div className="sm:flex sm:px-6 sm:py-5">
                          <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                            Website
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:ml-6 sm:mt-0">
                            <time dateTime="1982-06-23">
                              {enableEdit && currentTab == "PROFILE" ? (
                                <input
                                  id="website"
                                  name="website"
                                  type="text"
                                  defaultValue={franchisee?.website}
                                  onChange={handleChange}
                                  placeholder="website"
                                  className="block w-full rounded-full border-0 px-4 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                              ) : (
                                franchisee?.website
                              )}
                            </time>
                          </dd>
                        </div>

                        <div className="p-5">
                          <DescriptionForm />
                        </div>
                      </dl>
                    </div>
                  )}

                  {currentTab == "FRANCHISE" && (
                    <div className="px-4 py-5 sm:px-0 sm:py-0">
                      <dl className="space-y-8 sm:space-y-0 sm:divide-y sm:divide-gray-200">
                        <div className="flex justify-between items-center">
                          <div className="">
                            <h2
                              id="franchise"
                              className="text-base uppercase font-semibold leading-6 text-gray-900 border-b p-5"
                            >
                              FRANCHISE DETAILS
                            </h2>
                          </div>
                          <div className="">
                            {enableEdit ? (
                              <>
                                <button
                                  onClick={(e) => handleSubmit(e)}
                                  className="mr-5  hover:bg-indigo-700 bg-indigo-600 w-20 py-1 rounded-md text-white "
                                >
                                  {" "}
                                  Save
                                </button>
                                <button
                                  onClick={(e) => setEnableEdit(false)}
                                  className="mr-5  hover:bg-red-700 bg-red-600 w-20 py-1 rounded-md text-white "
                                >
                                  {" "}
                                  Cancel
                                </button>
                              </>
                            ) : (
                              <button
                                onClick={(e) => handleEdit("FRANCHISE")}
                                className="mr-5  hover:bg-indigo-700 bg-indigo-600 w-20 py-1 rounded-md text-white "
                              >
                                {" "}
                                Edit
                              </button>
                            )}
                          </div>
                        </div>
                        <div className="sm:flex sm:px-6 sm:py-5">
                          <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                            Brand Name
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:ml-6 sm:mt-0">
                            {enableEdit && currentTab == "FRANCHISE" ? (
                              <input
                                id="franchise_name"
                                name="franchise_name"
                                type="text"
                                defaultValue={franchisee?.franchise_name}
                                onChange={handleChange}
                                placeholder="franchise_name"
                                className="block w-full rounded-full border-0 px-4 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                              />
                            ) : (
                              franchisee?.franchise_name
                            )}
                          </dd>
                        </div>
                        <div className="sm:flex sm:px-6 sm:py-5">
                          <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                            Type
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:ml-6 sm:mt-0">
                            {enableEdit && currentTab == "FRANCHISE" ? (
                              <select
                                id="looking_for"
                                name="looking_for"
                                type="text"
                                defaultValue={franchisee?.looking_for}
                                onChange={handleChange}
                                placeholder="looking_for"
                                className="block w-full rounded-full border-0 px-4 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                              >
                                <option>select option</option>
                                <option value="dealer">dealer</option>
                                <option value="distributor">distributor</option>
                                <option value="franchisee">franchisee</option>
                              </select>
                            ) : (
                              franchisee?.franchise_name
                            )}
                          </dd>
                        </div>
                        <div className="sm:flex sm:px-6 sm:py-5">
                          <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                            Unit BrandFee
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:ml-6 sm:mt-0">
                            <time dateTime="1982-06-23">
                              {franchisee?.unit_brandFee} Rs
                            </time>
                          </dd>
                        </div>
                        <div className="sm:flex sm:px-6 sm:py-5">
                          <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                            Unit Price
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:ml-6 sm:mt-0">
                            <time dateTime="1982-06-23">
                              {franchisee?.unit_price}
                            </time>
                          </dd>
                        </div>
                        <div className="sm:flex sm:px-6 sm:py-5">
                          <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                            Unit Commission
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:ml-6 sm:mt-0">
                            <time dateTime="1982-06-23">
                              {franchisee?.unit_commission || "N/A"}{" "}
                              {franchisee?.unit_commission < 100 ? "%" : "Rs"}
                            </time>
                          </dd>
                        </div>

                        {franchisee?.state_price && (
                          <>
                            <div className="sm:flex sm:px-6 sm:py-5">
                              <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                                State Brand Fee
                              </dt>
                              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:ml-6 sm:mt-0">
                                <time dateTime="1982-06-23">
                                  {franchisee?.state_brandFee || "N/A"} Rs
                                </time>
                              </dd>
                            </div>
                            <div className="sm:flex sm:px-6 sm:py-5">
                              <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                                State Price
                              </dt>
                              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:ml-6 sm:mt-0">
                                <time dateTime="1982-06-23">
                                  {franchisee?.state_price || "N/A"} Rs
                                </time>
                              </dd>
                            </div>
                            <div className="sm:flex sm:px-6 sm:py-5">
                              <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                                Additionally, competitive State Commission
                              </dt>
                              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:ml-6 sm:mt-0">
                                <time dateTime="1982-06-23">
                                  {franchisee?.state_commission || "N/A"}{" "}
                                  {franchisee?.state_commission < 100
                                    ? "%"
                                    : "Rs"}
                                </time>
                              </dd>
                            </div>
                          </>
                        )}

                        {franchisee?.country_price && (
                          <>
                            <div className="sm:flex sm:px-6 sm:py-5">
                              <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                              Country Brand Fee
                              </dt>
                              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:ml-6 sm:mt-0">
                                <time dateTime="1982-06-23">
                                  {franchisee?.country_brandFee || "N/A"} Rs
                                </time>
                              </dd>
                            </div>
                            <div className="sm:flex sm:px-6 sm:py-5">
                              <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                              Country Price
                              </dt>
                              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:ml-6 sm:mt-0">
                                <time dateTime="1982-06-23">
                                  {franchisee?.country_price || "N/A"} Rs
                                </time>
                              </dd>
                            </div>
                            <div className="sm:flex sm:px-6 sm:py-5">
                              <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                                Additionally, competitive Country Commission
                              </dt>
                              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:ml-6 sm:mt-0">
                                <time dateTime="1982-06-23">
                                  {franchisee?.country_commission || "N/A"}{" "}
                                  {franchisee?.country_commission < 100
                                    ? "%"
                                    : "Rs"}
                                </time>
                              </dd>
                            </div>
                          </>
                        )}

                        {franchisee?.city_price && (
                          <>
                            <div className="sm:flex sm:px-6 sm:py-5">
                              <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                              City Brand Fee
                              </dt>
                              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:ml-6 sm:mt-0">
                                <time dateTime="1982-06-23">
                                  {franchisee?.city_brandFee || "N/A"} Rs
                                </time>
                              </dd>
                            </div>
                            <div className="sm:flex sm:px-6 sm:py-5">
                              <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                              City Price
                              </dt>
                              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:ml-6 sm:mt-0">
                                <time dateTime="1982-06-23">
                                  {franchisee?.city_price || "N/A"} Rs
                                </time>
                              </dd>
                            </div>
                            <div className="sm:flex sm:px-6 sm:py-5">
                              <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                                Additionally, competitive City Commission
                              </dt>
                              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:ml-6 sm:mt-0">
                                <time dateTime="1982-06-23">
                                  {franchisee?.city_commission || "N/A"}{" "}
                                  {franchisee?.city_commission < 100
                                    ? "%"
                                    : "Rs"}
                                </time>
                              </dd>
                            </div>
                          </>
                        )}

                        <div className="sm:flex sm:px-6 sm:py-5">
                          <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                            Selected City
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:ml-6 sm:mt-0">
                            {franchisee?.selectedCities &&
                              Object.entries(franchisee.selectedCities).map(
                                ([key, value]) => (
                                  <div key={key} className="mb-1">
                                    <strong>{key}: </strong>
                                    <span>{value.toString()}</span>
                                  </div>
                                )
                              )}
                          </dd>
                        </div>

                        <div className="sm:flex sm:px-6 sm:py-5">
                          <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                            Selected States
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:ml-6 sm:mt-0 sm:flex gap-2 ">
                            {franchisee?.selectedStates &&
                              Object.entries(franchisee.selectedStates).map(
                                ([key, value]) => (
                                  <div
                                    key={key}
                                    className="mb-1 bg-green-200 px-3 py-1 border rounded-sm text-green-600 text-xs text-center"
                                  >
                                    <strong>{key} </strong>
                                    {/* <span>{value.toString()}</span> */}
                                  </div>
                                )
                              )}
                          </dd>
                        </div>
                      </dl>
                    </div>
                  )}

                  {currentTab == "BUSINESS" && (
                    <div className="px-4 py-5 sm:px-0 sm:py-0">
                      <dl className="space-y-8 sm:space-y-0 sm:divide-y sm:divide-gray-200">
                        <div className="flex justify-between items-center">
                          <div>
                            <h2
                              id="franchise"
                              className="text-base uppercase font-semibold leading-6 text-gray-900 border-b p-5"
                            >
                              BUSINESS DETAILS
                            </h2>
                          </div>
                          <div>
                            {enableEdit ? (
                              <>
                                <button
                                  onClick={(e) => handleSubmit(e)}
                                  className="mr-5 hover:bg-indigo-700 bg-indigo-600 w-20 py-1 rounded-md text-white"
                                >
                                  {" "}
                                  Save
                                </button>
                                <button
                                  onClick={(e) => setEnableEdit(false)}
                                  className="mr-5 hover:bg-red-700 bg-red-600 w-20 py-1 rounded-md text-white"
                                >
                                  {" "}
                                  Cancel
                                </button>
                              </>
                            ) : (
                              <button
                                onClick={(e) => handleEdit("BUSINESS")}
                                className="mr-5 hover:bg-indigo-700 bg-indigo-600 w-20 py-1 rounded-md text-white"
                              >
                                {" "}
                                Edit
                              </button>
                            )}
                          </div>
                        </div>
                        {/* <div className="sm:flex sm:px-6 sm:py-5">
                          <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                            BUSINESS
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:ml-6 sm:mt-0">
                            <p>
                              Enim feugiat ut ipsum, neque ut. Tristique mi id
                              elementum praesent. Gravida in tempus feugiat
                              netus enim aliquet a, quam scelerisque. Dictumst
                              in convallis nec in bibendum aenean arcu.
                            </p>
                          </dd>
                        </div> */}
                        <div className="sm:flex sm:px-6 sm:py-5">
                          <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                            Industry
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:ml-6 sm:mt-0">
                            {franchisee?.industry}
                          </dd>
                        </div>
                        <div className="sm:flex sm:px-6 sm:py-5">
                          <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                            Commenced
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:ml-6 sm:mt-0">
                            {franchisee?.Commenced}
                          </dd>
                        </div>
                        <div className="sm:flex sm:px-6 sm:py-5">
                          <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                            Current Outlets
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:ml-6 sm:mt-0">
                            <time dateTime="1982-06-23">
                              {enableEdit && currentTab == "PROFILE" ? (
                                <select
                                  id="currentOutlets"
                                  name="currentOutlets"
                                  value={franchisee.currentOutlets}
                                  onChange={handleChange}
                                  className="mt-1 block w-full rounded-sm border-0 py-[9px] pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300  focus:ring-indigo-600 text-[12px] sm:leading-6"
                                >
                                  <option>
                                    Select No of Franchise Outlets
                                  </option>
                                  <option>Less than 10</option>
                                  <option>10-20</option>
                                  <option>20-50</option>
                                  <option>50-100</option>
                                  <option>100-200</option>
                                  <option>200-500</option>
                                  <option>500-1000</option>
                                  <option>1000-10000</option>
                                  <option>More than 10000</option>
                                </select>
                              ) : (
                                franchisee?.currentOutlets
                              )}
                            </time>
                          </dd>
                        </div>
                        <div className="sm:flex sm:px-6 sm:py-5">
                          <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                            Franchising On
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:ml-6 sm:mt-0">
                            <time dateTime="1982-06-23">
                              {" "}
                              {franchisee?.franchisingOn}
                            </time>
                          </dd>
                        </div>
                        <div className="sm:flex sm:px-6 sm:py-5">
                          <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                            Minimum investment range for Tier 1 cities
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:ml-6 sm:mt-0">
                            <time dateTime="1982-06-23">
                              {" "}
                              {enableEdit && currentTab == "BUSINESS" ? (
                                <select
                                  id="investment_range_tier_1"
                                  name="investment_range_tier_1"
                                  value={franchisee.investment_range_tier_1}
                                  onChange={handleChange}
                                  className="mt-1 block w-full rounded-sm border-0 py-[9px] pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300  focus:ring-indigo-600 text-[12px] sm:leading-6"
                                >
                                  <option>Select Min Investment Range</option>
                                  <option value="Rs. 10000 - 50000">
                                    Rs. 10000 - 50000
                                  </option>
                                  <option value="Rs. 50000 - 2lakh">
                                    Rs. 50000 - 2lakh
                                  </option>
                                  <option value="Rs. 2lakh - 5lakh">
                                    Rs. 2lakh - 5lakh
                                  </option>
                                  <option value="Rs. 5lakh - 10lakh">
                                    Rs. 5lakh - 10lakh
                                  </option>
                                  <option value="Rs. 10lakh - 20lakh">
                                    Rs. 10lakh - 20lakh
                                  </option>
                                  <option value="Rs. 20lakh - 30lakh">
                                    Rs. 20lakh - 30lakh
                                  </option>
                                  <option value="Rs. 30lakh - 50lakh">
                                    Rs. 30lakh - 50lakh
                                  </option>
                                  <option value="Rs. 50lakh - 1 Cr">
                                    Rs. 50lakh - 1 Cr.
                                  </option>
                                  <option value=" Rs. 1 Cr. - 2 Cr">
                                    {" "}
                                    Rs. 1 Cr. - 2 Cr.
                                  </option>
                                  <option value="Rs. 2 Cr. - 5 Cr">
                                    Rs. 2 Cr. - 5 Cr.
                                  </option>
                                  <option value="Rs. 5 Cr. above">
                                    Rs. 5 Cr. above
                                  </option>
                                </select>
                              ) : (
                                franchisee?.investment_range_tier_1
                              )}
                            </time>
                          </dd>
                        </div>
                        <div className="sm:flex sm:px-6 sm:py-5">
                          <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                            Minimum investment range for Tier 2 cities
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:ml-6 sm:mt-0">
                            <time dateTime="1982-06-23">
                              {" "}
                              {enableEdit && currentTab == "BUSINESS" ? (
                                <select
                                  id="investment_range_tier_2"
                                  name="investment_range_tier_2"
                                  value={franchisee.investment_range_tier_2}
                                  onChange={handleChange}
                                  className="mt-1 block w-full rounded-sm border-0 py-[9px] pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300  focus:ring-indigo-600 text-[12px] sm:leading-6"
                                >
                                  <option>Select Min Investment Range</option>
                                  <option value="Rs. 10000 - 50000">
                                    Rs. 10000 - 50000
                                  </option>
                                  <option value="Rs. 50000 - 2lakh">
                                    Rs. 50000 - 2lakh
                                  </option>
                                  <option value="Rs. 2lakh - 5lakh">
                                    Rs. 2lakh - 5lakh
                                  </option>
                                  <option value="Rs. 5lakh - 10lakh">
                                    Rs. 5lakh - 10lakh
                                  </option>
                                  <option value="Rs. 10lakh - 20lakh">
                                    Rs. 10lakh - 20lakh
                                  </option>
                                  <option value="Rs. 20lakh - 30lakh">
                                    Rs. 20lakh - 30lakh
                                  </option>
                                  <option value="Rs. 30lakh - 50lakh">
                                    Rs. 30lakh - 50lakh
                                  </option>
                                  <option value="Rs. 50lakh - 1 Cr">
                                    Rs. 50lakh - 1 Cr.
                                  </option>
                                  <option value=" Rs. 1 Cr. - 2 Cr">
                                    {" "}
                                    Rs. 1 Cr. - 2 Cr.
                                  </option>
                                  <option value="Rs. 2 Cr. - 5 Cr">
                                    Rs. 2 Cr. - 5 Cr.
                                  </option>
                                  <option value="Rs. 5 Cr. above">
                                    Rs. 5 Cr. above
                                  </option>
                                </select>
                              ) : (
                                franchisee?.investment_range_tier_2
                              )}
                            </time>
                          </dd>
                        </div>
                        <div className="sm:flex sm:px-6 sm:py-5">
                          <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                            Owned Outlets
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:ml-6 sm:mt-0">
                            <time dateTime="1982-06-23">
                              {" "}
                              {enableEdit && currentTab == "BUSINESS" ? (
                                <select
                                  id="ownedOutlets"
                                  name="ownedOutlets"
                                  value={franchisee.ownedOutlets}
                                  onChange={handleChange}
                                  className="mt-1 block w-full rounded-sm border-0 py-[9px] pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300  focus:ring-indigo-600 text-[12px] sm:leading-6"
                                >
                                  <option>
                                    Select No of Franchise Outlets
                                  </option>
                                  <option>Less than 10</option>
                                  <option>10-20</option>
                                  <option>20-50</option>
                                  <option>50-100</option>
                                  <option>100-200</option>
                                  <option>200-500</option>
                                  <option>500-1000</option>
                                  <option>1000-10000</option>
                                  <option>More than 10000</option>
                                </select>
                              ) : (
                                franchisee?.ownedOutlets
                              )}
                            </time>
                          </dd>
                        </div>
                        <div className="sm:flex sm:px-6 sm:py-5">
                          <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                            Retail Outlets
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:ml-6 sm:mt-0">
                            <time dateTime="1982-06-23">
                              {" "}
                              {franchisee?.retailOutlets}
                            </time>
                          </dd>
                        </div>
                        <div className="sm:flex sm:px-6 sm:py-5">
                          <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                            sector
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:ml-6 sm:mt-0">
                            <time dateTime="1982-06-23">
                              {" "}
                              {franchisee?.sector}
                            </time>
                          </dd>
                        </div>
                        <div className="sm:flex sm:px-6 sm:py-5">
                          <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                            Service
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:ml-6 sm:mt-0">
                            <time dateTime="1982-06-23">
                              {" "}
                              {franchisee?.service}
                            </time>
                          </dd>
                        </div>
                      </dl>
                    </div>
                  )}

                  {currentTab == "TRAINING" && (
                    <div className="px-4 py-5 sm:px-0 sm:py-0">
                      <dl className="space-y-8 sm:space-y-0 sm:divide-y sm:divide-gray-200">
                        <h2
                          id="franchise"
                          className="text-base uppercase font-semibold leading-6 text-gray-900 border-b p-5"
                        >
                          TRAINING DETAILS
                        </h2>
                        <div className="sm:flex sm:px-6 sm:py-5">
                          <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                            TRAINING
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:ml-6 sm:mt-0">
                            <p>
                              Enim feugiat ut ipsum, neque ut. Tristique mi id
                              elementum praesent. Gravida in tempus feugiat
                              netus enim aliquet a, quam scelerisque. Dictumst
                              in convallis nec in bibendum aenean arcu.
                            </p>
                          </dd>
                        </div>

                        <div className="sm:flex sm:px-6 sm:py-5">
                          <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                            operating manuals for franchises
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:ml-6 sm:mt-0">
                            {franchisee?.operating_manuals}
                          </dd>
                        </div>
                        <div className="sm:flex sm:px-6 sm:py-5">
                          <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                            franchisee training Place
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:ml-6 sm:mt-0">
                            <time dateTime="1982-06-23">
                              {franchisee?.franchise_training_location}
                            </time>
                          </dd>
                        </div>
                        <div className="sm:flex sm:px-6 sm:py-5">
                          <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                            IT systems For Franchise
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:ml-6 sm:mt-0">
                            <time dateTime="1982-06-23">
                              {franchisee?.presently_IT_system}
                            </time>
                          </dd>
                        </div>
                        <div className="sm:flex sm:px-6 sm:py-5">
                          <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                            field assistance available
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:ml-6 sm:mt-0">
                            <time dateTime="1982-06-23">
                              {" "}
                              {franchisee?.field_assistance_available}
                            </time>
                          </dd>
                        </div>
                        <div className="sm:flex sm:px-6 sm:py-5">
                          <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                            head office be providing assistance
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:ml-6 sm:mt-0">
                            <time dateTime="1982-06-23">
                              {franchisee?.providing_assistance_byhead}
                            </time>
                          </dd>
                        </div>
                        <div className="sm:flex sm:px-6 sm:py-5">
                          <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                            standard franchise agreement
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:ml-6 sm:mt-0">
                            <time dateTime="1982-06-23">
                              {franchisee?.franchise_agreement}
                            </time>
                          </dd>
                        </div>

                        <div className="sm:flex sm:px-6 sm:py-5">
                          <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                            Duration of the Contract
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:ml-6 sm:mt-0">
                            <time dateTime="1982-06-23">
                              {franchisee?.contract_date}
                            </time>
                          </dd>
                        </div>

                        <div className="sm:flex sm:px-6 sm:py-5">
                          <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                            renewable
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:ml-6 sm:mt-0">
                            <time dateTime="1982-06-23">
                              {franchisee?.renewable}
                            </time>
                          </dd>
                        </div>
                      </dl>
                    </div>
                  )}

                  {currentTab == "PROPERTY" && (
                    <div className="px-4 py-5 sm:px-0 sm:py-0">
                      <dl className="space-y-8 sm:space-y-0 sm:divide-y sm:divide-gray-200">
                        <div className="flex justify-between items-center">
                          <div>
                            <h2
                              id="franchise"
                              className="text-base uppercase font-semibold leading-6 text-gray-900 border-b p-5"
                            >
                              PROPERTY DETAILS
                            </h2>
                          </div>
                          <div>
                            {enableEdit ? (
                              <>
                                <button
                                  onClick={(e) => handleSubmit(e)}
                                  className="mr-5 hover:bg-indigo-700 bg-indigo-600 w-20 py-1 rounded-md text-white"
                                >
                                  {" "}
                                  Save
                                </button>
                                <button
                                  onClick={(e) => setEnableEdit(false)}
                                  className="mr-5 hover:bg-red-700 bg-red-600 w-20 py-1 rounded-md text-white"
                                >
                                  {" "}
                                  Cancel
                                </button>
                              </>
                            ) : (
                              <button
                                onClick={(e) => handleEdit("PROPERTY")}
                                className="mr-5 hover:bg-indigo-700 bg-indigo-600 w-20 py-1 rounded-md text-white"
                              >
                                {" "}
                                Edit
                              </button>
                            )}
                          </div>
                        </div>
                        <div className="sm:flex sm:px-6 sm:py-5">
                          <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                            PROPERTY
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:ml-6 sm:mt-0">
                            <p>
                              Enim feugiat ut ipsum, neque ut. Tristique mi id
                              elementum praesent. Gravida in tempus feugiat
                              netus enim aliquet a, quam scelerisque. Dictumst
                              in convallis nec in bibendum aenean arcu.
                            </p>
                          </dd>
                        </div>
                        <div className="sm:flex sm:px-6 sm:py-5">
                          <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                            Preferred location
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:ml-6 sm:mt-0">
                            {franchisee?.Preferred_location}
                          </dd>
                        </div>
                        <div className="sm:flex sm:px-6 sm:py-5">
                          <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                            Floor Area
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:ml-6 sm:mt-0">
                            {enableEdit && currentTab == "PROPERTY" ? (
                              <input
                                id="floor_Area"
                                name="floor_Area"
                                type="number"
                                defaultValue={franchisee?.floor_Area}
                                onChange={handleChange}
                                placeholder="floor_Area"
                                className="block w-full rounded-full border-0 px-4 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                              />
                            ) : (
                              franchisee?.floor_Area
                            )}
                          </dd>
                        </div>
                        <div className="sm:flex sm:px-6 sm:py-5">
                          <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                            Property Type Required
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:ml-6 sm:mt-0">
                            <time dateTime="1982-06-23">
                              {franchisee?.property_type_requried}
                            </time>
                          </dd>
                        </div>
                      </dl>
                    </div>
                  )}

                  {currentTab == "KYC" && <InvestorKycDetail />}
                  {currentTab == "TRAN" && <InvestorTrans />}
                  {/* {currentTab == "PLAN" && <InvestorPlan />} */}
                  {currentTab == "DASHBOARD" && (
                    <InvestorLeads
                      franchisee={franchisee}
                      singleinvestorlead={singleinvestorlead}
                    />
                  )}
                  {currentTab == "UPLOADS" && <InvestorUpload />}
                  {currentTab == "MARKETING" && <MarketingDetails />}
                  {currentTab == "ads" && <Investorads />}
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>

      {openRechargeWalletModel && (
        <RechargeWallet closeModel={closeModel} id={auth?._id} />
      )}
      {openBuyLeadModel && (
        <BuyLeadModel closeModel={closeModel} id={auth?._id} />
      )}
      {openAdModel && <RunAd closeModel={closeModel} id={auth?._id} />}
    </>
  );
};

export default AdminFranchiseeProfile;
