// store.js
import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage"; 

import authReducer from "./authSlice";
import usersReducer from "./userSlice";
import franchiseReducer from "./franchiseSlice"
import ownFranchiseReducer from "./ownFranchiseSlice";
import paymentReducer from "./paymentSlice";
import planReducer from "./PlanSlice";
import leadReducer from "./leadUpdateSlice"
import homemanageReducer from "./homeManageSlice"
import adReducer from "./adSlice"
import locationReducer from "./locationSlice"


const rootReducer = combineReducers({
  auth: authReducer, 
  allusers: usersReducer,
  franchise:franchiseReducer ,
  ownFranchises: ownFranchiseReducer,
  buyPayment: paymentReducer,
  plans: planReducer,
  leads: leadReducer,
  homemanage: homemanageReducer,
  ad: adReducer,
  location: locationReducer,

});

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["auth"],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
});

export const persistor = persistStore(store);
